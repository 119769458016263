.pieChartContainer {
  width: 30%;
  height: 220px;
  margin: 0;
}

.pieChartBox {
  width: 50%;
}

.pieChartsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.pieChartsWrapper.right-wrapper {
  justify-content: flex-end;
}

.graph-sec.pieChartBoxWrapper {
  display: flex;
  justify-content: space-between;
}
.pieChartBoxWrapper .graph-box.pieChartBox {
  width: 45% !important;
}

.pieChartBoxWrapper .chartWrapper {
  max-width: 100%;
}

.rupeeGrants {
  font-size: 51px;
  line-height: 44px;
}

.croresGrants {
  align-self: flex-end;
  margin-bottom: 6px;
  padding-left: 4px;
}

.summaryGrants {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.headingGrants {
  padding: 0 20px;
}

.engagement-box p {
  display: inline-block;
  width: 60px;
  text-align: right;
  vertical-align: top;
}

.events-engagement .coordinate-tabs {
  z-index: auto;
}

.events-engagement .dropdown-tab-wrap  {
  display: flex;
}

.events-engagement .coordinate-tabs .wrapper {
  display: flex;
  height: 100%;
  padding-left: 50px;
}

.events-engagement .coordinate-tabs .tabs {
  display: flex;
  align-items: center;
}

.tabular-view-upper-chart.left-section-table {
  padding: 0 16px 16px;
}

.grants-and-loans-table {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  margin: 24px 32px 0;
}

.grants-and-loans-table > ul {
  width: 100%;
}

.grants-and-loans-table-row {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 20px;
}

.left-section-table-row td {
  width: 50%;
  text-align: right;
}

.grants-and-loans-table-row .grants-and-loans-table-row-radio {
  height: 14px;
  width: 14px;
  border: 2.5px solid;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
}

.grants-and-loans-table-year {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .events-engagement .dropdown-tab-wrap {
    flex-direction: column;
  }
  .events-engagement .coordinate-tabs .wrapper {
    padding-left: 12px;
  }
  .pieChartContainer {
    width: 43%;
  }
  .grants-and-loans-table {
    margin: 24px 16px 0;
  }
}

@media (max-width: 767px) {
  .pieChartBoxWrapper .graph-box.pieChartBox {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }
  .pieChartContainer {
    width: 33%;
  }
  .graph-sec.pieChartBoxWrapper {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .pieChartContainer {
    width: 50%;
  }
}
