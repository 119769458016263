.footprint-mission-card {
    border-radius: 5px;
    background-color: #f4f4f4;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 25px;
    float: left;
}

.footprint-mission-card .head-wrap h3 {
    float: left;
    max-width: calc(100% - 85px);
}

.footprint-mission-card .head-wrap span {
    display: block;
    float: right;
    padding-right: 18px;
    letter-spacing: 1px;
    font-size: 59px;
    line-height: 1.1;
    color: #253567;
}

.footprint-mission-card .head-wrap h3 .top,
.footprint-mission-card .head-wrap h3 .bottom {
    font-size: 35px;
    line-height: 50px;
    display: block;
    background-color: #3aaa8c;
    color: white;
    padding-left: 10px;
    padding-right: 30px;
    float:left;
    clear:both;
}

.footprint-mission-card .head-wrap h3 .bottom {
    background-color: #d35c43;
    padding-right: 20px;
}

.footprint-mission-card .card-content ul {
    padding-top: 25px;
}

.footprint-mission-card .card-content li {
    font-size: 24px;
    color: #253567;
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    padding-left: 40px;
    position: relative;
    margin-bottom: 15px;
}

.footprint-mission-card .card-content li::before {
    content: "";
    display: block;
    width: 40px;
    height: 30px;
    position: absolute;
    top: -2px;
    left: 0;
    background: url(/images/mission-tag.png) no-repeat center top / 30px;
}

.footprint-mission-card .card-content li:nth-of-type(odd) {
    margin-left: 20px;
    width: calc(50% - 20px);
}

@media (max-width: 480px) {
  .footprint-mission-card .card-content li {
    width: 100%;
  }
  .footprint-mission-card .card-content li:nth-of-type(odd) {
    margin-left: 0;
    width: 100%;
  }
  .footprint-mission-card .head-wrap h3 {
    max-width: calc(100% - 65px);
  }
}

