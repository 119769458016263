.svg {
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  cursor: pointer;
  transform-origin: left center;
  pointer-events: none;
}

.svg g {
  pointer-events: painted;
}

.svgWrapper {
  overflow: hidden;
  top: -47px;
  left: -5px;
  position: relative;
  transform-origin: 300px 300px;
  transition: transform 0.4s ease-in-out;
  max-width: 400px;
  float: left;
}

.ellipsis {
  font-size: 34px;
  color: #ffffff;
}

.outer-circle {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 270px;
  left: -80px;
  position: absolute;
  background: #7467DE;
  z-index: 123;
  background-image: -moz-linear-gradient(17deg, rgb(215, 90, 85) 0%, rgb(69, 76, 221) 100%);
  background-image: -webkit-linear-gradient(17deg, rgb(215, 90, 85) 0%, rgb(69, 76, 221) 100%);
  background-image: -ms-linear-gradient(17deg, rgb(215, 90, 85) 0%, rgb(69, 76, 221) 100%);
  box-shadow: 6.071px 3.128px 17px 0 rgb(88, 88, 117);
}

.outer-circle:after {
  content: "";
  height: 145px;
  border-radius: 50%;
  width: 145px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-box-shadow: inset 2px 2px 2px rgba(255, 255, 255, .4), inset -2px -2px 2px rgba(0, 0, 0, .4);
  -webkit-box-shadow: inset 2px 2px 21px rgba(0, 0, 0, .2), inset 0px 0px 22px rgba(255, 255, 255, .3);
  box-shadow: inset 2px 2px 21px rgba(0, 0, 0, .2), inset 0px 0px 22px rgba(255, 255, 255, .3);
}

.inner-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 25px;
  right: 25px;
  position: absolute;
  background: #DFDBDC;
}

.rotation-arrows {
  z-index: 1;
  position: relative;
  left: -4px;
}

.inner-circle .rotation-arrows .arrow {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  left: 26px;
  margin: 5px;
  cursor: pointer;
  background: #ffffff;
  padding: 0;
  text-align: center;
  line-height: 36px;
  font-size: 24px;
  color: #263668;
  border: 1px solid #fff;
}

.inner-circle .rotation-arrows .arrow:hover {
  border-color: #e27961;
}

.inner-circle .rotation-arrows .arrow .icon:hover svg path {
  stroke: #e27961;
}

.inner-circle .rotation-arrows .arrow .icon {
  position: absolute;
  line-height: 0;

}

.inner-circle .rotation-arrows .arrow.upBtn .icon {
  top: 11px;
  left: 10px;
}

.inner-circle .rotation-arrows .arrow.downBtn .icon {
  top: 12px;
  left: 9px;
}

.circles {
  position: relative;
  left: -545px;
  top: -176px;
  transform: scale(0.5);
}

.rotateCircles {
  -webkit-animation: spin 2s infinite;
  animation: spin 2s infinite;
  animation-timing-function: linear;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}


@-webkit-keyframes spin-n {
  0% {
    transform: rotate(0deg) scale(0.5);
  }
  100% {
    transform: rotate(360deg) scale(0.5);
  }
}
@keyframes spin-n {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}