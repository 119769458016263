.map-slider-outer.mahatma-slide .map-slider .thumb-img{
width:212px;
height:250px;
border-top-right-radius:5px;
}
.map-slider-outer.mahatma-slide .map-slider .text-wrap{
    width:calc(100% - 212px);
    float:left;
    height:210px;
    background-color:#f4f4f4;
    background-image:url(../../../assets/images/gandhi-bg.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position:right bottom;
}
.map-slider-outer .slick-slide{

}
.map-slider .item{
    margin:0;
    padding:0 6px;

}
.map-slider-outer.mahatma-slide .map-slider .item{
    display:flex !important;
    align-items: flex-end;
}
.map-slider-outer .map-slider .thumb-img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow:hidden
}
.map-slider-outer.mahatma-slide .map-slider .thumb-img{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow: hidden;
}
.map-slider-outer .map-slider .text-wrap{
    border-bottom-right-radius:5px;
    border-bottom-left-radius:5px;
    overflow: hidden;
}
.map-slider-outer.mahatma-slide  .map-slider .text-wrap{
    border-top-right-radius:5px;
    border-bottom-left-radius:0;

    border-bottom-right-radius:5px;
    overflow: hidden;
}
.home-global-footprint .map-slider .thumb-img img{
    /*height:25vh;*/
}
.home-global-footprint .mahatma-slide  .map-slider .thumb-img img{
    max-height:100%;
    height:100%;
}
.map-slider-outer.mahatma-slide  .map-slider .text-wrap h4{
    font-size:35px;
    color:#253567;
}

.map-slider-outer .text-wrap small {
    font-size: 21px;
    color: rgb(37, 44, 59);
}

.home-global-footprint .slick-arrow {
  bottom: -42px;
}

@media (max-width: 480px) {
  .map-slider-outer.mahatma-slide .map-slider .item {
    flex-direction: column;
  }
  .map-slider-outer.mahatma-slide .map-slider .thumb-img {
    width: 100%;
    height: 350px;
  }
  .map-slider-outer.mahatma-slide .map-slider .text-wrap {
    width: 100%;
  }
}