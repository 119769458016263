.banner-inner:before{
    content:"";

    background-image:url(/images/banner-shape.png);
    position: absolute;
    z-index: 0;
    -webkit-animation: spin-n 30s linear infinite;
    animation: spin-n 30s linear infinite;
    background-size: cover;
    left: 42%;
    height: 554px;
    top: 0;
    width: 554px;
    background-size: cover;
    display:block;
    scale:1;
}
.banner-inner{
    background-image:none !important;
    display: flex;
    overflow: hidden;
}

.banner-right-data {
  display: flex;
}

@media (max-width: 991px) {
  .banner-inner {

    flex-direction: column;
  }
  .banner-left,
  .banner-right {
    float: none;
    width: 100%;
  }
  .banner-right {
    margin-top: 20px;
  }
  .banner-inner:before{
    left: auto;
    right: 0;
  }
}

@media (max-width: 767px) {
  .banner-inner {
    padding-top: 170px;
    margin-right: -20px;
  }
  .banner-right-data {
    flex-direction: column;
  }
  .banner-inner:before {
    left: 42%;
  }
}
