@font-face {
  font-family: 'libel_suitregular';
  src: url('assets/fonts/libel-suit-rg-webfont.woff2') format('woff2'),
  url('assets/fonts/libel-suit-rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Libel Suit';
  src: url('assets/fonts/libelsuit-webfont.woff2') format('woff2'),
  url('assets/fonts/libelsuit-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

body {
  font-family: Libel Suit, serif;
}

.hide-content {
  opacity: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFF;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fbc284;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #db3d51;
}

/* .App {
text-align: center;
background-color: purple;;
}
.App-logo {
-webkit-animation: App-logo-spin infinite 20s linear;
animation: App-logo-spin infinite 20s linear;
height: 40vmin;
pointer-events: none;
}
.App-header {
background-color: #282c34;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
color: white;
}
.App-link {
color: #61dafb;
}    */
@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.chartWrapper {
  /*height: 257px;*/
  /*width: 352px;*/
  /*margin: 16px;*/
}


/*
map tooltip
*/
.tooltip-pinkpoint {
  z-index: 100;
  position: absolute;
  display: none;
  padding: 10px;
  opacity: 0.9;
  background-image: linear-gradient(273deg, #db3d51, #e64868);
  border-radius: 5px 5px 5px 0;
  font-size: 16px;
  color: #fff;
}

.tooltip-pinkpoint:after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 100%;
  left: 0;
  border: 8px solid #e44663;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-color: transparent;
  border-left-width: 6px;
}

#tooltip-world {
  z-index: 100;
  position: absolute;
  display: none;
  padding: 10px;
  opacity: 0.9;
  background-image: linear-gradient( to left,  rgb(233,44,255) 0%, rgb(37,53,102) 100%);
  font-size: 16px;
  color: #fff;
  box-shadow: 0px 6px 13px 0px rgba(0, 0, 0, 0.19);
  border: 1px solid #fff !important;
  margin-left: 4px;
}

#tooltip-world:after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  top: 100%;
  left: -1px;
  border: 8px solid #fff;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-color: transparent;
  border-left-width: 10px;
}

.map-pointer {
  height: 20px;
  width: 20px;
  background: url('/images/pointer-red.png') no-repeat top left;
}

.graph-box-container {
  width: 50%;
}

.graph-box {
  background: #FFFFFF;
  border-radius: 5px;
  margin: 5px 5px;
  padding: 20px 0 0 0;
  min-height: 290px;
  width: 540px;
  box-shadow: 0px 0px 8px 0px rgba(95, 94, 95, 0.22);
}

.indicator {
  cursor: pointer;
}

.passport-report-view .wrapper .graph-sec .graph-box {
  width: calc(100%/3 - 10px);
}

.passport-report-view .highcharts-container {
  max-width: 100%;
}
.passport-report-view .highcharts-container svg {
  max-width: 100%;
}

.country-map-outer {
  background: url(/images/india-map-bg.png) no-repeat 0 0;
  width: 660px;
  height: 630px;
  margin-top: -145px;
}

.country-map-outer::before {
  content: '';
  background: url(/images/india-map-shape.png) no-repeat 0 0;
  width: 630px;
  height: 630px;
  position: absolute;
  -webkit-animation: mapRotate 30s linear infinite;
  animation: mapRotate 30s linear infinite;
}

@-webkit-keyframes mapRotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes mapRotate {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.country-map-outer .highcharts-label .highcharts-text-outline {
  /*todo - commented this because it was showing red stroke over blue lines */
  /*fill: #f8d3de;*/
  /*stroke: #f8d3de;*/
  /*stroke-width: 7px;*/
}

#world-map-container .highcharts-label .highcharts-text-outline {
  /*todo - commented this because it was showing red stroke over blue lines */
  /*fill: #f8d3de;*/
  /*stroke: #f8d3de;*/
  /*stroke-width: 7px;*/
}


.destination-sec .graph-sec .event-engagement{
  width: calc(33.33% - 7px);
  flex-grow: 1;
}


.country-map-outer .highcharts-root path {
  stroke-width: 1;
  stroke: rgba(34, 136, 83, 0.4);
}


.some-awesome-class-by-arun {
  background-image: url("./assets/pointer-red.png");
  height: 20px;
  width: 20px;
  background-size: 20px 20px;
  display: inline-block;
}

.pie-legend {
  position: absolute;
  top: 30%;
  transform: translateX(-50%);
  left: 50%;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 20px;
  width: 150px;
  box-shadow: 1px 2px 15px rgba(0,0,0,0.5);
}

.pie-legend .legend-tile{
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  align-items: center;
}

.pie-legend .legend-color {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 10px;
}
.dropdown-tab-wrap .select-outer span:nth-child(2){
  border: none;
  border-radius: 0;
  padding: 0;
  padding-left: 28px;
  font-size: 31px;
  color: #253567;
  padding-bottom: 11px;
  margin-bottom:0;
}
.dropdown-tab-wrap .select-outer .drop-wrap{
  position:relative;
  cursor:pointer;
}
.dropdown-tab-wrap .select-outer .drop-wrap:after{
  content:"";
  position: absolute;
  right: 10px;
  border: none;
  width: 32px;
  height: 10px;
  top: 25px;
  background: url(./assets/event-arrow.png) no-repeat center top /15px;
  transition: 0.3s;
}
.dropdown-tab-wrap .select-outer .drop-wrap.drop-wrap-open:after{
  transform: rotate(180deg);
  transition: 0.3s;
}

.custom-slick-controls {
  position: absolute;
  bottom: 40px;
  left: 20px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.slick-dots-custom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slick-dots-custom li {
  display: inline-block;
  opacity: .5;
}
.slick-dots-custom li.slick-active {
  display: inline-block;
  opacity: 1;
}

.slick-dots-custom li.slick-active button{
  background-color: #30c5ad;
}

.slick-dots-custom li button {
  width: 35px;
  height: 8px;
  background: #fff;
  border: none;margin-right: 10px;
  cursor: pointer;
  outline: none;
}

.arrow-left {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}

.arrow-right {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.arrow-left img, .arrow-right img {
  height: 14px;
  width: auto;
}

.no-pointer-events * {
  pointer-events: none;
}

.pointer-label-inner-maps {
  display: inline-block;
  height: 15px;
  width: 15px;
  background-size: 15px 15px;
  background-image: url("./assets/pointer-red.png");
}

.solar-alliance-outer .destination-heading-bg {
  top: -150px;
  left: -140px;
  width: 700px;
  height: 700px;
}

.solar-alliance-info .infoBox {
  background: transparent;
  margin-left: 65px;
  margin-top: 45px;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.solar-alliance-info .countryName {
  color: #223665;
  font-size: 36px;
  padding-top: 0;
}

.solar-alliance-info .description {
  color: #223665;
  font-size: 24px;
}

.solar-alliance-info .solar-img {
  width: 60px;
  margin-right: 15px;
}

.ent-table-title-container {
  min-height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ent-table .ent-table-title-container p {
  width: auto;
}

.ent-table {
  padding: 12px
}

.ent-table-row {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 20px;
}

.ent-table-row .ent-table-row-radio{
  height: 14px;
  width: 14px;
  border: 2.5px solid;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
}

.ent-table-year {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.trend-select-graph .select-box .select-outer {
  padding-top: 0;
}
.trend-select-graph .select-box select {
  border: 2px solid;
  width: 100%;
  font-family: Libel Suit, serif;
}

.trend-image-container {
  position: absolute;
  top: 10px;
  right: 5px;
}

.trend-image-container img {
  width: 55px;
  height: 55px;
}
.events-engagement .coordinate-tabs .tabs{
  text-align:left;
}
.trend-image-container{
  top:0;
  right:0;
  background-image:url(/images/social-bg.png);
  background-repeat: no-repeat;
  background-size:cover;
  height:137px;
  width:121px;
  box-sizing: border-box;
  padding-top: 14px;
    padding-left: 42px;
}
.trend-image-container img{
  width:70px;
  height:auto;
}
.citizen-section.wheel-content-container > div{
  display:flex;
  align-items: center;
  /*height: 100vh;*/
}
.citizen-section.wheel-content-container > div > div:first-child {
  align-self: flex-start;
}
.section.citizen-section{
  height:auto;
}

.citizen-section .slick-list {
  padding: 0 20% 0 0 !important;
  margin: 0 -20px;
  max-width: calc(100% + 40px);
}
.citizen-section .slick-arrow + .slick-list {
  padding: 0 20% 60px 0 !important;
}


/***css for amit mall */
[class*="pointer_"]{
  transition: all 0.3s;
}
[class*="pointer_"]:hover{
  margin-top:-3px;
  transform:scale(1.2)
}
#tooltip-world{
  display: block !important;
  transition: all 0.3s;
  opacity: 0;
  height: 30px;
  line-height: 0.75;
  margin-top: 15px;
  white-space: nowrap;
  margin-left: 0px;
}
.home-global-footprint #tooltip-world + div{
  transform: scale(1.2);
  width: 100%;
}

#tooltip-world[style*="z-index"]{
  opacity: 1;
}
/*.home-global-footprint{
  height: 750px;
}*/

.home-global-footprint .world-map .map-inner{
  margin-top: 55px;
  /*margin-right: -7vw;*/
}
.home-global-footprint #world-map-container svg{
  filter:drop-shadow( 0px 23px 12px rgba(0, 0, 0, .5));
}
section:after{
  content: '';
  clear: both;
  display: table;
  width: 100%;
}
#fullpage > section{
  float: left;
  width: 100%
}
section section{
  float: none
}

@keyframes scroll {
	0% {
		transform: translateY(0);
	}
	30% {
		transform: translateY(60px);
	}
}

svg #wheel {
	animation: scroll ease 2s infinite;
}

.scroll-down{
  position: absolute;
  bottom:0px;
  left:50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-align: center;
}
.scroll-down svg{
  height: 40px;
}

.scroll-down p{
  color: #fff;
  margin-top: 10px;
}


@media (max-width: 1024px) {
  .destination-sec .graph-sec .event-engagement{
    width: calc(50% - 5px);
  }
}

@media (max-width: 991px) {
  .citizen-section.wheel-content-container > div {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 0;
  }
  .citizen-section .select-box {
    width: 100%;
  }
  .citizen-section .select-box select {
    width: 100%;
    font-family: Libel Suit, serif;
  }
  .citizen-right {
    width: 100%;
  }
  .passport-report-view .wrapper .graph-sec .graph-box {
    width: calc(50% - 10px);
  }
  #fullpage > section.slider {
    z-index: 0;
    margin-top: -100px;
    margin-bottom: -100px;
  }
  .custom-slick-controls {
    bottom: 140px;
  }
  .solar-alliance-outer .chart-outer {
    height: 0;
  }
  #world-map-container.inner-page-world-map .solar-alliance-info {
    left: 0;
    top: 0;
    width: 100%;
  }
  #world-map-container.inner-page-world-map .solar-alliance-info .infoBox {
    margin-left: 0;
  }
}

@media (max-width: 767px) {
  .passport-report-view .wrapper .graph-sec .graph-box {
    width: calc(100% - 10px);
    margin-bottom: 20px;
  }
  .passport-report-view .wrapper .graph-sec .graph-box:last-child {
    margin-bottom: 0;
  }
  .destination-sec .graph-sec .event-engagement {
    width: 100%;
  }
  #fullpage > section.slider {
    margin: 0;
  }
  .custom-slick-controls {
    bottom: 40px;
  }
  .pie-legend {
    position: sticky;
    bottom: 0;
    width: calc(100% + 40px);
    transform: none;
    margin: 0 -20px;
    display: inline-block;
    border-radius: 0;
  }
  .pie-legend .legend-tile {
    display: inline-flex;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .dropdown-tab-wrap .select-outer span:nth-child(2) {
    font-size: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 30px;
  }
}