.countInfo {
  display: flex;
  align-items: center;
}

.countInfoOuterWrapper {
  display: flex;
  flex-direction: column;
}

.asOnDate {
  color: rgba(0, 0, 0, 0.66);
  padding-bottom: 8px;
}

.totalCount {
  font-size: 48px;
  line-height: 50px;
}

.totalCountDescription {
  font-size: 17px;
  padding-left: 8px;
}

@media (max-width: 480px) {
  .countInfoOuterWrapper {
    margin-top: 45px;
    margin-bottom: -20px;
  }
}
