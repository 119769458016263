.worldMapLandingPageChart {
  display: flex;
  flex-direction: column;
}

.worldMapLandingPageChart .graph-box {
  width: 100%;
  height: 334px;
  margin: 0;
}
.worldMapLandingPageChart .highcharts-container {
  width: 100% !important;
}
.worldMapLandingPageChart .highcharts-container svg {
  width: 100%;
}

.worldMapLandingPageChart .graph-box .chartOuterWrapper,
.worldMapLandingPageChart .graph-box .chartWrapper {
  height: 100%;
  position: relative;
}

.worldMapLandingPageChart .graph-box .heading {
  padding: 0 20px 0 20px;
  height: 50px;
}
