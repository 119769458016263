.footprint-left-head{
    padding-top:30px;
    float:left;
    margin-top: 40px;
}
.footprint-left-head h2{
    font-size:44px;
    line-height:1.1;
    padding-bottom:20px;
    position: relative;
    color:white;
    margin-bottom:20px;
}
.footprint-left-head h2:before{
content:"";
display:block;
height:3px;
width:121px;
position: absolute;
left:0;
bottom:0;
background-color:#d6502b;
border-radius:2px;
}

.footprint-left-head .subTitle {
  display: block;
}
.down-head .small-box{
  float:left;
  margin-bottom:33px;
}
.down-head .small-box span{
  font-size:50px;
  color:#bec0c2;

}
.down-head .small-box small{
  font-size:20px;
  padding-left:10px;
  color:rgba(255,255,255,0.7);
}

@media (max-width: 480px) {
  .footprint-left-head h2 {
    font-size: 34px;
    padding-bottom: 10px;
  }
  .down-head .small-box span {
    font-size: 40px;
  }
}
