.full-page-controls{
    position: fixed;
    top:0;
    right:30px;
    height:auto;
    top:50%;
    transform:translateY(-50%);
    z-index: 9;
    display:inline-block;
}
.full-page-controls button:first-child,
.full-page-controls button:last-child{
    display:none;
}
.full-page-controls button{
font-size:0;
width:15px;
height:15px;
border-radius:50%;
border:2px solid #fff;
margin-bottom:5px;
background-color:rgba(36,68,140, 0.5);
display:block;
}
.full-page-controls button:hover,
.full-page-controls button:disabled{
    background-color:#cf4b30;
}
#fullpage > div > div:last-child{
    height:auto !important;
}
.animated{
    /*opacity: 0;*/
    /*animation-duration: 1s;*/
    /*animation-delay: 0;*/
}
.destination-sec.india-footprint{
    position: relative;
    background-color: #ffffff;
    max-width: 1380px;
    margin: 0 auto;
}
.india-footprint-wrapper {
    background-color: #fff;
}

.india-footprint-wrapper:before{
    background:linear-gradient(to bottom, #aeddee 0, #ffffff 100%);
    content:"";
    height:50%;
    width:100%;
    position: absolute;
    left:0;
    top:0;
}
.landing-page-india-map .country-map-outer{
    position: relative;
}
.landing-page-india-map .country-map-outer:after{
    content: '';
    position: absolute;
    background: url(/images/india-map-shadow.png) no-repeat 0 0px;
    width: 504px;
    height: 195px;
    bottom: -90px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.landing-page-india-map .india-text{
    position: absolute;
    text-align: right;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 1380px;
    width: 100%;
    padding: 0 20px;
}
.destination-sec.india-footprint{
    /*height:100vh;*/
    padding-top:40px;
    overflow: visible;
    box-shadow: none;
    background-color: transparent;
    overflow: hidden;
}
.india-map-tabs li a{
    padding:7px 10px;
}
.india-footprint .destination-heading .destination-heading-bg{
    width:686px;
    height:686px;
    left:-255px;
    top:-260px;
}
.india-footprint .destination-heading .destination-heading-bg.right{
    left: 87px;
    top: -143px;
}
.landing-page-india-map .country-map-outer {
    transform:scale(0.9);
    margin-left: 30px;
}
.landing-page-india-map .country-map-outer .highcharts-container {
  top: 30px !important;
}
.landing-page-india-map .country-map-outer svg {
  margin-left: 100px !important;
}
.india-footprint .chart-outer > h3{
    background-image:url(/images/map-icon-n.png);
}


@media (max-width: 1280px) {
  .landing-page-india-map .country-map-outer{
    margin-left: 0;
  }
}

@media (max-width: 1200px) {
  .landing-page-india-map .country-map-outer{
    transform: scale(0.85);
    margin-left: -45px;
    width: 700px;
    margin-top: -70px;
  }
  .india-footprint .destination-heading .destination-heading-bg {
    width: 550px;
    background-size: 100%;
  }
  .india-footprint .destination-heading .destination-heading-bg.right {
    width: 550px;
    background-size: 100%;
    left: 50px;
  }
}

@media (max-width: 1100px) {
  .landing-page-india-map .country-map-outer {
    transform: scale(0.8);
    margin-left: -23px;
    width: 770px
  }
  .india-footprint .graph-box-container .graph-box {
    width: 100%;
    margin: 0;
  }
}

@media (max-width: 1024px) {
  .landing-page-india-map .country-map-outer {
    width: 810px;
    margin-right: -80px;
  }
}
