.inaugurated-page .sub-title {
  font-size: 34px;
  padding-top: 70px;
  padding-bottom: 65px;
  position: relative;
  text-align: center;
}

.inaugurated-page .sub-title:after {
  content: '';
  height: 1px;
  width: 180px;
  background-color: #000;
  position: absolute;
  bottom: 50px;
  left: calc(50% - 95px);
}

.inaugurated-page .sub-title:before {
  content: '';
  height: 1px;
  width: 90px;
  background-color: #000;
  position: absolute;
  bottom: 35px;
  left: calc(50% - 50px);
}

.inaugurated-banner {
  position: relative;
  height: 320px;
  overflow: hidden;
}

.inaugurated-banner  .banner-inner:before{
  left: initial;
  right: 0;
}

.inaugurated-banner img {
  width: 100%;
  height: 700px;
}

.inaugurated-banner .title {
  color: #fff;
  font-size: 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.inaugurated-card-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1245px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 66px;
  padding-bottom: 60px;
  width: 100%;
}

.inaugurated-card {
  background-color: #fff;
  cursor: pointer;
  width: calc(100%/3 - 32px);
  margin: 0px 16px 40px;
  position: relative;
  -webkit-transition: all .4s;
  transition: all .4s;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0px 5px 30px 3px rgba(6, 6, 6, 0.08);
}
.inaugurated-card:hover{
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

/* .inaugurated-card:after {
  background-color: #31c5ae;
  content: '';
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
} */

.inaugurated-card .inaugurated-img {
  position: relative;
}

.inaugurated-card .inaugurated-img .country {
  background-color: #d75a55;
  border-top-right-radius: 5px;
  color: #fff;
  display: inline-block;
  font-size: 25px;
  padding: 5px 15px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.inaugurated-card img {
  min-width: 275px;
  min-height: 220px;
  height: 220px;
  width: 100%;
  object-fit: cover;
}

.inaugurated-card .card-title {
  font-size: 24px;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.inaugurated-card .published-date {
  font-size: 16px;
  padding-bottom: 13px;
  padding-left: 10px;
}

.inaugurated-card .amount {
  color: #d75a55;
  font-size: 16px;
  padding-bottom: 13px;
  padding-left: 10px;
}

.inaugurated-card .short-description {
  font-size: 16px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  line-height: 24px;
  color: #5c6074;
}


.ReactModal__Overlay {
  z-index: 10;
}

.ReactModal__Content{
  overflow-y: auto !important;
  border-radius: 0px !important;
}

.ReactModal__Body--open{
  overflow: hidden;
}

.load-more-btn {
  background-color: #d75a55;
  border: 0;
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  height: 60px;
  letter-spacing: 0.5px;
  width: 336px;
}

.center-content {
  margin-top: 70px;
  text-align: center;
  width: 100%;
}


.project-detail-modal {
  position: relative;
}

.project-detail-modal .inaugurated-card-modal {
  width: 900px;
}

.inaugurated-card-modal .close-icon {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 5px;
  z-index: 99;
  width: 27px;
  height: 27px;
  background: url('../../../assets/images/pop-upclose.png') no-repeat 0 0;
}

.inaugurated-card-modal .inaugurated-img {
  position: relative;
}


.inaugurated-card-modal .inaugurated-img .amount {
  background-color: #d77c37;
  color: #fff;
  display: inline-block;
  font-size: 30px;
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

/*.inaugurated-card-modal .inaugurated-img img{*/
/*  !*height: 420px;*!*/
/*  !*width: 900px;*!*/
/*  max-width: 100%;*/
/*  !*margin: -16px 0 0 -1px;*!*/
/*}*/

.inaugurated-card-modal .card-title {
  font-size: 32px;
  padding-bottom: 10px;
}

.inaugurated-card-modal .published-date {
  font-size: 16px;
  padding-bottom: 13px;
  color: #3b3f53;
}

.inaugurated-card-modal .description {
  font-size: 16px;
  padding-bottom: 30px;
  color: #5c6074;
  line-height: 24px;
}
.inaugurated-card-modal{
  display: flex;
  height: 100%;
}
.inaugurated-card-modal .inaugurated-img{
  width: 75%;
  align-items: center;
  justify-content: center;
  /*display: flex;*/
  background: #000;

}
.inaugurated-card-modal .inaugurated-img img{
  max-width: 100%;
  max-height: 100%;
  width: auto!important;


}
.inaugurated-card-modal .inaugurated-img .slick-slider{
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.inaugurated-card-modal .inaugurated-img .slick-slider .slick-list{
  height: 100%;
}
.inaugurated-img .slick-slider .slick-list .slick-track{
  height: 100%;
  width: 100%;
}
.inaugurated-img .slick-slider .slick-list .slick-slide{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.inaugurated-img .slick-slider .slick-list .slick-slide > div{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.inaugurated-card-modal .inaugurated-img button.slick-arrow{
  width: 40px;
  height: 41px;
  display: inline-block;
  background: url("../../../assets/images/slick-arrow.png") transparent 0 0;
  bottom: 20px;
  top:auto;
  z-index: 1;
}
.inaugurated-card-modal .inaugurated-img button.slick-prev{
  right: 70px;
  left: auto;
  transform: rotate(180deg);
}
.inaugurated-card-modal .inaugurated-img button.slick-next{
  right: 20px;
  transform: rotate(360deg);
}
.slick-prev:before, .slick-next:before{
  color: transparent;
}
.inaugurated-card-modal .content-modal{
  width: 25%;
  padding: 50px 30px 30px;
  height: 100%;
  overflow-y: auto;
}
.inaugurated-card-modal .content-modal .amount{
  font-size: 26px;
  margin-bottom: 13px;
  display: block;
  color: #ff8604;
}

.pm-inaugrated-outer{
  position: relative;
  margin-top: -80px;
  margin-bottom: 40px;
  min-height: 327px;
}
.pm-inaugrated-outer .wrapper{
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 8px 0 rgba(95,94,95,.22);
  padding: 0;
}

.pm-launch-heading{
  position: relative;
  padding: 30px 20px 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  line-height: 1.5;
  background-color: #F5F5F5;
  height: 185px;
}

.pm-launch-heading .select-outer{
  width: 370px;
  text-align: left;
  padding-bottom: 0;
  border-radius: 5px;
  box-shadow: -2px 5px 32px 0px rgba(6, 6, 6, 0.08);
  line-height: 1.1;
  top: 32px;
}

.pm-launch-heading .select-inner:before{
  content: initial;
}

.pm-launch-heading .select-inner:after{
  content: initial;
}

.pm-launch-heading .head1{
  font-size: 18px;
}

.pm-launch-heading .head2{
  font-weight: 500;
  font-size: 25px;
}

.pm-launch-heading .head2 strong{
  color: #d75a55;
  font-weight: 500;
}

.pm-launch-heading .drop-wrap span:first-child{
  color: rgba(37, 44, 59, 0.6);
}

.pm-launch-heading .drop-wrap span:nth-child(2){
  color: #252c3b;
}

.pm-launch-heading .drop-wrap small{
  font-size: 17px;
}

.pm-launch-heading .select-outer:before{
  border-radius: 5px;
}

.modiji-image{
  position: absolute;
  width: 535px;
  bottom: 0;
  right: 12px;
}

.count-img{
  position: absolute;
    bottom: 6px;
    right: 15px;
    background: rgba(0, 0, 0, 0.5);
    padding-right: 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.count-img svg{
  transform: scale(0.6);
}

.count-img span{
  color: #fff;
  font-size: 20px;
  font-style: normal;
  padding-top: 2px;
}

@media screen and (max-width: 1200px){
  .pm-inaugurated .header{
    width: calc(100% - 45px);
  }
  .pm-inaugurated .header a{
    margin: 0;
  }
  .inaugurated-card{
    width: calc(100%/3 - 40px);
    margin: 0 20px 40px;
  }
}

@media screen and (max-width: 991px) {
  .inaugurated-card {
    width: calc(100%/2 - 40px);
    margin: 0 20px 30px;
  }
  .modiji-image {
    width: 335px;
  }
}

@media screen and (max-width: 767px) {
  .inaugurated-page {
    padding-bottom: 30px;
  }

  .inaugurated-card-container {
    width: calc(100% - 40px);
  }

  .inaugurated-card {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0;
    margin-left: 0;
  }

  .center-content {
    margin-top: 30px;
  }

  .load-more-btn {
    max-width: 336px;
    width: 100%;
  }

  .inaugurated-card-modal .card-title {
    font-size: 24px;
  }

  .inaugurated-card-modal .description {
    font-size: 16px;
  }

  .ReactModal__Overlay {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    z-index: 9999;
  }
  .inaugurated-card-modal{
    display: block;
    height: auto;
  }

  .inaugurated-card-modal .close-icon {
    top: 5px;
    right: 5px;
  }

  .inaugurated-card-modal .inaugurated-img{
    width: 100%;
  }
  .inaugurated-card-modal .inaugurated-img .slick-slider{
    display: block;
    height: auto;
  }
  .modiji-image {
    width: 260px;
    bottom: -62px;
    right: 28px;
  }
  .inaugurated-card-modal .content-modal{
    width: 100%;
    padding: 35px 20px 30px;
    height: auto;
  }
  .inaugurated-card-modal .inaugurated-img img{
    max-height: 400px;
  }
  .inaugurated-card{
    margin-right: 0;
  }
  .inaugurated-banner .banner-inner:before{
    left: 42%;
    right: initial;
  }
  .pm-inaugrated-outer{
    margin-top: 0;
  }
  .pm-launch-heading{
    border-top-left-radius: 0; 
    border-top-right-radius: 0; 
  }
  .pm-launch-heading .head1{
    font-size: 16px;
  }
  .pm-launch-heading .head2{
    font-size: 22px;
  }
 
  .inaugurated-card-container{
    margin-top: 88px;
    padding-top: 66px;
  }
  .pm-launch-heading .select-outer{
    margin-top: 50px;
    top: 110px;
    min-width: 240px;
    width: 100%;
  }
  .pm-inaugurated .header {
    width: 100%;
  }
}
.count-slide{
  position: absolute;
  background: rgba(0,0,0,.6);
  font-size: 20px;
  color: #fff;
  display: inline-block;
  padding: 5px 10px;
  bottom: 20px;
  left: 10px;
  z-index: 9;
  border-radius: 16px;
}

@media screen and (max-width: 520px){
  .modiji-image {
    width: 100%;
    left: 0;
    text-align: center;
  }
  .modiji-image img{
    width: 238px;
  }
}
