.header .logoAnimaton img{
    max-width: 372px;
}

.header .logoAnimaton{
    position: relative;
}
.header .logoAnimaton:before{
    /* content:"";
    display:block;
    position: absolute;
    left:25px;
    top:-2px;
    width:56px;
    height:56px;
    -webkit-animation: spin 30s linear infinite;
    animation: spin 30s linear infinite;
    background-image:url(../../assets/images/smart-spin.svg);
    background-size: 56px 56px;
    transform: scale(0.5); */
}

.header{
    padding-top:10px;
    width: 100%;
}
.section.citizen-section{
    /*height:100vh;*/
}
.logoAnimaton {
    display: inline-flex;
    align-items: center;
}

.logo-mea {
  max-width: 269px;
}
.visit-dashboard-wrap{
  position: absolute;
  right: -25px;
  display: none;
}
.smartBoardLogo{
  font-size: 30px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  padding-left: 80px;
}
.smartBoardLogo:before{
  content:"";
    display:block;
    position: absolute;
    left:25px;
    top:-13px;
    width:56px;
    height:56px;
    -webkit-animation: spin 30s linear infinite;
    animation: spin 30s linear infinite;
    background-image:url(../../assets/images/smart-spin.svg);
    background-size: 56px 56px;
    transform: scale(0.5);

}

.visits-dashboard h3{
  text-transform: uppercase;
    position: relative;
    padding-left: 41px;
    font-size: 30px;
}
.visits-dashboard h3:before{
  content:"";
  display:block;
  position: absolute;
  left: 0;
  top: 0;
  width:31px;
  height:30px;
  background-image:url(../../assets/images/visits-Icon.png);
}
.visits-dashboard{
  background: #f47a20;
    border-bottom-left-radius: 38px;
    border-top-left-radius: 11px;
    text-transform: uppercase;
    color: #fff;
    padding: 10px 45px 10px 30px;
    margin-right: 0;
}
.visits-dashboard span{
  text-transform: capitalize;
  position: relative;    
  font-size: 20px;
  padding-right: 32px;
  vertical-align: middle;

}
.visits-dashboard span:before{
  content: "";
  display: block;
  position: absolute;
  right: 1px;
  top: 2px;
  width: 21px;
  height: 19px;
  background-image:url(../../assets/images/arrow-d.png);
}

@media (max-width: 991px) {

  .visits-dashboard h3{
    padding-left: 4px;
  }

  .visits-dashboard h3:before{
    position: relative;
  }
}

@media (max-width: 767px) {
  .header {
    flex-direction: column;
    padding:25px 20px;
  }
  .header a.logoAnimaton {
    margin-top: 8px;
    margin-right: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.6);
    padding-top: 5px;
  }
  .header .logoAnimaton:before {
    top: -3px;
    left: -12px;
  }
  .header .logoAnimaton img{
    object-position: -30px 0;
    margin-right: -30px;
  }
  .logo-mea {
    margin-bottom: 10px!important;
    padding-bottom: 10px;
    border-bottom: 1px solid #ffff;
    display: block;
    max-width: 229px;
  }
  .smartBoardLogo{
    padding-left: 38px;
    display: block;
    font-size: 22px;
  }
  .smartBoardLogo:before{
    left: -13px;
    top:-17px;
  }
  .visit-dashboard-wrap{
    top:7px;
    right: 0;
  }
  .visits-dashboard{
    padding-right: 20px;
  }
  .visits-dashboard h3:before{
    margin-bottom: 12px;
    top:4px;
  }
  .wrapper.header-wrap{
    padding: 0;
  }
}

@media (max-width: 480px) {
  .header a:first-child img {
    max-width: 180px;
  }
  .header a.logoAnimaton {
    margin-top: 4px;
    padding-top: 2px;
  }
  .header .logoAnimaton:before {
    width: 35px;
    height: 35px;
    background-size: 100%;
    top: -2px;
  }
  .header .logoAnimaton img{
    max-width: 250px;
  }
  #fullpage > section.slider {
    margin: 0;
  }
}