.home-global-footprint>.world-map>.wrapper>.word-map:after {
    content: "";
    display: block;
    clear: both;
}

.home-global-footprint>.world-map>.wrapper>.word-map {
    width: 65%;
    float: right;
}

#fullpage .citizen-section {
    overflow: hidden;
}

.home-global-footprint .world-map .map-inner {
    width: 100%;
}

.home-global-footprint #world-map-container {
    width: 100%;
}
.home-global-footprint .word-map .highcharts-container  {
    margin-left: -230px;
    width: 1025px !important;
}
.home-global-footprint .graph-box-container {
    display: none;
}

.home-global-footprint .world-map .map-inner .map-inner {
    background-image: none;
}
.home-global-footprint .left-template-box{
    float:left;
    width: 35%;
    position: relative;
    z-index: 1;
}

.home-global-footprint .left-template-box.tab1 {
  /*width: 402px;*/
}

.home-global-footprint .map-slider .thumb-img img {
  height: 200px;
  object-fit: cover;
}
.coordinate-section.home-global-footprint{
    position: relative;
    /*min-height: 100vh;*/
    overflow: hidden;
    /*height: 100vh;*/
}
.world-map > .wrapper .worldmap-dropdown{
    position: absolute;
    bottom: -30px;
    right: 10px;
    font-size:16px;
    color:#b8b8b8;
    background-color:#2d3132;
    box-shadow: 0px 12px 36px 4px rgba(0, 0, 0, 0.21);
    border-radius:5px;
    width:225px;
    line-height:42px;
}
.world-map > .wrapper .worldmap-dropdown .select-box{
    position: relative;
    padding-left:20px;
}
.world-map > .wrapper .worldmap-dropdown .select-box:after{
    content:"";
    display:block;
    height:6px;
    position: absolute;
    width:12px;
    background-image:url(../../assets/images/white-arrow.png);
    background-size:cover;
    transition:0.4;
    right:10px;
    top:50%;
    transform:translateY(-50%);
}
.world-map .wrapper{
    /*padding-top:0;*/
}
.peice-disclaimer{
    clear:both;
    text-align: right;
    padding-right: 10px;
    /*padding-right:50px;*/
}
.peice-disclaimer div{
    position: relative;
    margin-top:33px;
    /*padding-bottom:40px;*/
    display:inline-block;
    line-height: 20px;
    font-size:16px;
    color:rgba(255,255,255,0.4);
}
.peice-disclaimer div:first-child{
    padding-right:7px;
    margin-right:7px;

}
.peice-disclaimer div:first-child:after{
content:"";
display:block;
height:15px;
width:1px;
background-color:rgba(255,255,255,0.4);
position: absolute;
right:0;
top:0;

}
.peice-disclaimer div:last-child {
  padding-right: 0;
  margin-right: 0;
}
.peice-disclaimer div:last-child:after {
  content: none;
}
.world-map .wrapper > .word-map{
    position: relative;
}
.world-map .map-inner{
    /*height:66vh;*/
    background-image:none;
}
.world-map .wrapper{
    /*padding-right:0;*/
}



#canvas {
    /* make the canvas wrapper fits the document */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width:100% !important;
    height:100% !important;
}
#canvas canvas{
    width:100% !important;
    height:100% !important;
}
.plane {
    /* define the size of your plane */
    width: 100%;
    height: 100%;
    position: absolute;
}
.plane img {
    /* hide the img element */
    display: none;
}
#canvas:after{
    content:"";
    height:50px;
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    background:linear-gradient(to bottom, rgba(226,226,226,0) 0%, rgba(33,35,34,1) 100%);
}

.slider-price {
  position: absolute;
  color: #fff;
  z-index: 9999999;
  background: #f0721b;
  bottom: 0;
  font-size: 20px;
  padding: 3px 5px;
}

.slider-inauguration-date {
  color: #627587;
  font-size: 16px;
}

@media (max-width: 1320px) {
  .home-global-footprint #tooltip-world + div {
    transform: scale(1.1);
  }
  .home-global-footprint .word-map .highcharts-container {
    margin-left: -260px;
  }
  .aeroplane-container {
    transform: scale(0.9);
    left: -30px;
  }
}

@media (max-width: 1220px) {
  .home-global-footprint #tooltip-world + div {
    transform: scale(1);
  }
  .home-global-footprint .word-map .highcharts-container {
    margin-left: -290px;
  }
  .aeroplane-container {
    transform: scale(0.8);
    left: -55px;
  }
}

@media (max-width: 1120px) {
  .home-global-footprint #tooltip-world + div {
    transform: scale(0.9);
  }
  .home-global-footprint .word-map .highcharts-container {
    margin-left: -320px;
  }
}

@media (max-width: 1024px) {
  .home-global-footprint .left-template-box {
    width: 40%;
  }
  .home-global-footprint>.world-map>.wrapper>.word-map {
    width: 60%;
  }
  .home-global-footprint #tooltip-world + div {
    transform: scale(0.8);
  }
  .home-global-footprint .word-map .highcharts-container {
    margin-left: -350px;
  }
  .aeroplane-container {
    transform: scale(0.7);
    left: -55px;
  }
}

@media (max-width: 991px) {
  #section2.home-global-footprint {
    height: auto;
  }
  .world-map {
    /*padding-bottom: 24px;*/
    height: auto;
  }
  .world-map > .wrapper .worldmap-dropdown {
    width: 100%;
    margin-top: 16px;
    position: static;
  }

  .home-global-footprint .left-template-box {
    width: 100%;
  }

  .footprint-left-head {
    margin-top: 0;
    width: 100%;
  }
  .left-template-box .footprint-left-head .summary {
    width: 100%;
  }

  /*.footprint-mission-card .head-wrap h3 .top,
  .footprint-peace-card .head-wrap h3 .top {
    display: none; /*todo check with design team*/
  /*}*/

  .peice-disclaimer {
    display: flex;
    flex-direction: column;
    padding-left: 4px;
    text-align: left;
  }

  .peice-disclaimer div {
    padding: 0;
    margin-top: 20px;
  }

  .peice-disclaimer div:first-child {
    padding-top: 16px;
  }

  .peice-disclaimer div:first-child:after {
    display: none;
  }

  .world-map > .wrapper .worldmap-dropdown .css-5e5njw-menu {
    top: 0;
    bottom: auto;
  }
  #fullpage .citizen-section {
    z-index: 1;
    position: relative;
  }
}

@media (max-width: 480px) {
  .footprint-left-head ul {
    flex-wrap: wrap;
  }
  .footprint-left-head li {
    margin-bottom: 10px;
    margin-right: 10px;
    width: calc(50% - 10px) !important;
  }
  .footprint-left-head li > div {
    padding-right: 0 !important;
  }
}
