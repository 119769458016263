.landing-page-india-map {
  margin-top: -210px;
}

.india-footprint .destination-heading .destination-heading-bg{
  /*top:-234px;*/
}

.india-footprint .chart-outer{
  padding-left:20px;
}

.india-footprint .chart-outer > h3{
  color: #252c3b;
  font-size: 44px;
  letter-spacing: 2px;
  display: block;
  background: url(/images/map-icon.png) no-repeat 0 0px;
  padding-left: 65px;
  padding-top: 3px;
  background-size: 49px;
  margin-top: 16px;
  /*float:left;*/
}

.india-footprint .destination-heading {
  display: block;
  float: none;
  text-align: left;
  margin: 24px 0 0 0;
}

.india-footprint .destination-heading h3 {
  clear:both;
}

.india-footprint .destination-heading h3:after {
  position: absolute;
}

.india-footprint .india-map-tabs{
  z-index: 2;
  position: relative;
  clear: none;
  float: none;
  display: inline-block;
}

.india-footprint .graph-box-container {
  margin-top: 80px;
}

.india-footprint .graph-box-container.noGraph {
  margin-top: 160px;
}

.india-map-tabs li {
  margin-right: 8px;
}

.india-map-tabs li:last-child {
  margin-right: 0;
}

.india-footprint .destination-heading .inner {
  width: 550px;
}
.india-footprint .headingWrapper {
  display: flex;
}

.india-footprint .chartWrapper {
  position: relative;
  z-index: 2;
}

@media (max-width: 1200px) {
  .india-footprint .destination-heading .inner {
    width: 450px;
  }
}

@media (max-width: 1100px) {
  .india-footprint .destination-heading .inner {
    width: 400px;
  }
}

@media (max-width: 991px) {
  .india-footprint-wrapper .destination-sec.india-footprint {
    padding-top: 8px;
  }

  .india-footprint .india-map-tabs {
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    border-radius: 0;
  }
  .india-footprint .graph-box-container {
    width: 100%;
    margin-top: 0;
  }
  .landing-page-india-map {
    margin-top: 0;
    margin-bottom: 16px;
  }
  .landing-page-india-map select {
    width: calc(100% - 32px);
    margin: 0 16px;
    position: absolute;
    top: 170px;
    font-family: Libel Suit, serif;
  }
  #india-map-container {
    margin-top: 0;
  }

  .india-footprint .graph-box-container.noGraph {
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .india-footprint .destination-heading {
    max-width: 100%;
    margin-top: 95px;
  }

  .india-footprint .destination-heading .inner {
    width: 100%;
  }

  .india-footprint .headingWrapper {
    width: calc(100% - 32px);
    margin: 0 auto;
  }

  /*.india-footprint .headingWrapper h3 > span {
    display: none; /*todo check with design team*/
  /*}*/
}

@media (max-width: 480px) {
  .india-footprint .chart-outer {
    padding-right: 20px;
  }
  .india-footprint .chart-outer > h3 {
    font-size: 40px;
  }
  .india-footprint .headingWrapper {
    flex-direction: column; 
  }
  .india-footprint .graph-box-container.noGraph {
    margin-top: 50px;
  }
}

@media (max-width: 401px) {
  .landing-page-india-map select {
    top: 210px;
  }
}
