.summary {
  color: white;
  display: inline-block;
  padding-bottom: 20px;
  width: 85%;
}

.left-template-box.tab1 .summary {
  width: auto;
}
