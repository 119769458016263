.infoContainer {
  position: relative;
  width: 400px;
  top: 100px;
  left: -80px; /* container is flex-end, can't change that yet */
  margin-left: 16px;
}

.infoBox {
  background: white;
  height: 140px;
  padding-left: 20px;
  border-radius: 4px;
}

.countryName {
  font-size: 28px;
  padding-top: 20px;
}

.description {
  font-size: 20px;
}

#world-map-container.inner-page-world-map {
  z-index: 2;
}

#world-map-container.inner-page-world-map .graph-box {
  margin-top: 0;
}
