.select-outer {
  z-index: 3;
}

.select-outer select {
  cursor: pointer;
}

.destination-outer {
  position: relative;
  z-index: 1;
}

@media (max-width: 991px) {
  .inner-page-map-section .destination-sec {
    display: flex;
    flex-direction: column;
  }

  .inner-page-map-section .chart-outer {
    display: flex;
    padding: 0 20px;
    align-items: center;
    justify-content: center;
  }

  .inner-page-map-section .destination-data-wrap {
    position: relative;
    margin-left: auto;
    transform: none;
    left: 0;
    margin-right: 0;
    margin-top: 0;
  }

  .inner-page-map-section .destination-sec .inner-world-map-outer {
    padding: 16px;
  }

  .left-section-table {
    padding-bottom: 0;
  }

  .inner-page-map-section .select-outer,
  .inner-page-map-section .select-box,
  .inner-page-map-section select {
    width: 100%;
  }
  .inner-page-map-section select {
    font-family: Libel Suit, serif;
  }

  .inner-page-map-section .select-box {
    position: relative;
    top: auto;
    right: auto;
  }

  .inner-page-map-section .graph-box-container {
    width: 100%;
  }

  .inner-page-map-section .destination-heading .inner {
    width: 100%;
  }

  #world-map-container .graph-box, #india-map-container .graph-box {
    width: 100%;
    margin-left: 0;
  }

  .inner-page-map-section .destination-heading {
    margin: 25px 0;
    max-width: none;
  }

  /*below two styles have disturbed tooltips (use highcharts library - responsive property) */
  .inner-page-map-section .highcharts-container {
    width: 100% !important;
  }

  .inner-page-map-section .highcharts-container > svg {
    width: 100% !important;
  }
}

@media (max-width: 480px) {
  .inner-page-map-section .chart-outer {
    flex-direction: column;
  }
  .inner-page-map-section .destination-data-wrap {
    margin-left: 0;
  }
}
