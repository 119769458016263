
.pointer_1 {
  cursor: pointer;
  background-image: url("/images/pointer/mission-tag.png");
  height: 20px;
  width: 20px;
  background-size: 100%;
  display: inline-block;
}

.pointer_2 {
  cursor: pointer;
  background-image: url("/images/pointer/dollar-marker.png");
  height: 24px;
  width: 20px;
  background-size: 100%;
  display: inline-block;
}


.pointer_3 {
  cursor: pointer;
  background-image: url("/images/pointer/solar.png");
  height: 27px;
  width: 20px;
  background-size: 100%;
  display: inline-block;
}

.pointer_3_red {
  cursor: pointer;
  background-image: url("/images/pointer/yellow-solar.png");
  height: 27px;
  width: 20px;
  background-size: 100%;
  display: inline-block;
}

.pointer_4 {
  cursor: pointer;
  background-image: url("/images/pointer/red-dot.png");
  height: 20px;
  width: 20px;
  background-size: 20px 20px;
  display: inline-block;
}

.pointer_5 {
  cursor: pointer;
  background-image: url("/images/pointer/green-bar.png");
  height: 96px;
  width: 28px;
  background-size: cover;
  display: inline-block;
}

.pointer_6 {
  cursor: pointer;
  background-image: url("/images/pointer/dollar-marker.png");
  height: 24px;
  width: 20px;
  background-size: 100%;
  display: inline-block;
}

.pointer_7, .pointer_10 {
  cursor: pointer;
  background-image: url("/images/pointer/gandhi-marker.png");
  height: 20px;
  width: 20px;
  background-size: 20px 20px;
  display: inline-block;
  transform: scale(1.1)
}

.pointer_8 {
  cursor: pointer;
  background-image: url("/images/pointer/book.png");
  height: 25px;
  width: 20px;
  background-size: 100%;
  display: inline-block;
}
.pointer_9 {
  cursor: pointer;
  background-image: url("/images/pointer/indian-culture.png");
  height: 24px;
  width: 25px;
  background-size: 100%;
  display: inline-block;
  margin-left: -2px;
}
