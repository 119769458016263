
.tabList {
  display: inline-flex;
  background: #fff;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 7.5px 10px;
}

.tabList .tab {
  cursor: pointer;
  margin-right: 10px;
  font-size: 17px;
  padding-right: 12px;
  padding-left: 12px;
  height: 35px;
  line-height: 35px;
  letter-spacing: .68px;
}

.tabList .tab:last-child {
  margin-right: 0;
}

.tabList .tab.selected {
  color: #fff;
  border-radius: 2.5px;
  background-image: linear-gradient(272deg, #25a180, #34b08f);
}

@media (max-width: 480px) {
  .tabList {
    padding: 0;
  }
  .tabList .tab.selected {
    margin-right: 0;
  }
}