.info-card {
  width: 550px;
  display: flex;
  box-shadow: 0px 16px 68px 0px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  overflow: hidden;
  margin-left: 20px;
  position: relative;
  z-index: 2;
  height: 168px;
  align-items: center;
  background: #f4f4f4;
  padding: 10px;
}

.info-card .img-wrap {
  /*background-color: #f4f4f4;*/
  width: 140px;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

.info-card .img-wrap img {
  /*margin-top: 15px;*/
}

.info-card .content-wrap {
  flex-grow: 1;
  /*background-color: #f4f4f4;*/
}

.info-card h4 {
  font-size: 18px;
  padding-bottom: 16px;
  color: #253567;

}

.info-card p {
  font-size: 30px;
  color: #253567;
}

.info-card .content-wrap .inner {
  padding: 26px;
}

@media (max-width: 1200px) {
  .info-card {
    width: 450px;
    margin-right: 45px;
  }
}

@media (max-width: 1100px) {
  .info-card {
    width: 395px;
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .info-card {
    width: calc(100% - 32px);
    margin: 0 auto;
  }
  .img-wrap {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .info-card {
    flex-direction: column;
    height: auto;
    text-align: center;
  }
}
