.footprint-peace-card {
	border-radius: 5px;
	background-color: #f4f4f4;
	width: 462px;
	padding-top: 15px;
	padding-bottom: 25px;
	float:left;
	width:100%;
}
.footprint-peace-card .head-wrap h3 {
	float: left;
	max-width: calc(100% - 85px);
}
.footprint-peace-card .head-wrap strong {
	display: block;
	float: right;
	padding-right: 18px;
	letter-spacing: 4px;
	font-size: 59px;
	line-height: 1.1;
	color: #253567;
}
.footprint-peace-card .head-wrap h3 .top,
.footprint-peace-card .head-wrap h3 .bottom {
	font-size: 35px;
	line-height: 50px;
	display: block;
	background-color: #5850cb;
	color: white;
	padding-left: 10px;
	padding-right: 8px;
	float:left;
	clear:both;
}
.footprint-peace-card .head-wrap h3 .bottom {
	background-color: #b450b5;
	padding-right: 13px;
}
.footprint-peace-card .card-content span {
	font-size: 59px;
	color: #253567;
	margin-bottom: 20px;
}
.footprint-peace-card .card-content {
	padding: 35px 22px 0px 25px;
	display: flex;
}

.footprint-peace-card .card-content .data-box {
  width: 300px;
  flex-grow: 0;
}

.footprint-peace-card .card-content .img-wrap {
	width: 100px;
	align-self: flex-end;
	margin-bottom: 10px;
}

.data-box small {
	color: rgba(37, 44, 59, 0.7);
	font-size: 21px;
  display: block;
}

@media (max-width: 991px) {
  .footprint-peace-card .card-content .img-wrap {
    width: 100px;
    margin-left: auto;
  }
}

@media (max-width: 480px) {
  .footprint-peace-card .head-wrap h3 {
    max-width: calc(100% - 65px);
  }
}