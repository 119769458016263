.homepage-global-map {
  background: white;
}

.homepage-global-map.grants-and-loans-table {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 0;
  height: 100%;
}

.worldMapLandingPageChart .grants-and-loans.graph-box .chartOuterWrapper {
  height: calc(100% - 73px);
}

.homepage-global-map.grants-and-loans-table > ul {
  height: 100%;
  width: 100%;
  margin: 16px 16px 0;
}

.homepage-global-map.grants-and-loans-table .grants-and-loans-table-row {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px;
}

.homepage-global-map.grants-and-loans-table .grants-and-loans-table-row .grants-and-loans-table-row-radio {
  height: 14px;
  width: 14px;
  border: 2.5px solid;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
}

.homepage-global-map.grants-and-loans-table .grants-and-loans-table-year {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.homepage-global-map.grants-and-loans-table .grants-and-loans-table-row td {
  width: 40%;
  text-align: right;
}

.homepage-global-map.grants-and-loans-table .grants-and-loans-table-row td:first-child {
  width: 20%;
  justify-content: flex-start;
}

.tableHeader {
  width: 100%;
  text-align: right;
  font-size: 14px;
  table-layout: fixed;
  padding: 2px 12px;
  height: 26px;
}

.tableRowsWrapper {
  overflow: auto;
  height: calc(100% - 26px);
}

.tableHeaderCell {
  width: 40%;
}
.tableHeaderCell:first-child {
  width: 20%;
  text-align: left;
}
