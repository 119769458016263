/****************reset css starts here*****************/
h1, h2, h3, h4, h5, h6, p, ul, li, body, html, form, fieldset, figure {
  margin: 0;
  padding: 0;
  outline: none;
  font-weight: normal;
  border: 0
}

form, fieldset {
  float: left;
  width: 100%
}

img {
  border: 0 none;
  max-width: 100%;
  height: auto;
  vertical-align: middle
}

a, input, select {
  text-decoration: none;
  border: 0;
  outline: 0;
}

ul {
  list-style: none;
}

.clear {
  clear: both;
}

/****************body*****************/
body, html {
  height: 100%;
}

body {
  font-family: 'libel_suitregular', arial sans-serif;
  color: #231f20;
  font-size: 14px;
  background: #e3e0e1;
  overflow-x: hidden;
  letter-spacing: 1px;
  -webkit-font-smoothing: antialiased;
}

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box
}

a {
  transition: all 0.5s ease;
}

/* layout section start here */
.wrapper {
  max-width: 1380px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

/*site-dots*/
.site-dots {
  position: fixed;
  right: 30px;
  z-index: 9999;
  transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%);
  top: 50%;
}

.site-dots li {
  border: 2px solid #fff;
  border-radius: 50%;
  background: rgba(36, 68, 140, 0.5);
  width: 15px;
  height: 15px;
  margin: 12px 0;
}

.site-dots li.active, .site-dots li:hover {
  background: #cf4b30;
}

/*header*/
.header {
  position: absolute;
  z-index: 9;
  /*width: 100%;*/
  top: 0;
  padding: 25px 0;
  transition: transform 0.5s;
  display: flex;
}

.header a {
  margin: 0 30px 0 0;
}

.header a:last-child {
  transition: all 1s ease-in-out;
  position: relative;
  top: 2px;
  /*todo - to be fixed*/
  left: -100px;
  visibility: hidden;
  opacity: 0;
}

.header a.logoAnimaton {
  left: 0;
  opacity: 1;
  visibility: visible;
}

/*Slider*/
.slider {
  width: 100%;
  overflow: hidden;
  position: relative;
  /*height: 100vh;*/
}

.slider img {
  width: 100vw;
  height: 100vh;
}

.slider .slick-dots {
  display: block;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  max-width: 1380px;
  padding: 0 20px;
  z-index: 999;
  text-align: right;
  margin: 0 auto;
}

.slider .slick-dots li {
  display: inline-block;
  margin: 0 0 0 6px;
  text-indent: -999px;
}

.slider .slick-dots li button {
  display: inline-block;
  width: 60px;
  height: 8px;
  text-indent: -999px;
  overflow: hidden;
  background: #65be83;
  border: 2px solid #000;
  outline: none;
  cursor: pointer;
}

.slider .slick-dots li.slick-active button {
  background: #dc685a;
}

/*citizen Section*/
.citizen-section {
  background: #e3e0e1;
  width: 100%;
  height:100%;
}

.citizen-section .wheel-content-container .svgWrapper {
  max-height: 670px;
}

.citizen-right {
  float: right;
  width: calc(100% - 415px);
  padding: 20px 0;
}

.citizen-right .head {
  border-left: 3px solid #d75a55;
  padding-left: 18px;
}

.citizen-right .head h3 {
  font-size: 40px;
  color: #252c3b;
}

.citizen-right .head p {
  font-size: 14.5px;
  color: #252c3b;
  line-height: 27px;
}

.services {
  display: flex;
  margin-top: 26px;
  flex-wrap: wrap;
}

.services li {
  width: calc(25% - 10px);
  background: #fafafa;
  border-radius: 5px;
  margin: 5px 5px;
  /*height: calc(50vh - 16vh);*/
  transition: all 0.6s;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  border: 1px solid #fff;
}

.services li:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.services li a {
  display: block;
  padding: 10px 15px 50px;
}

.services li .icon {
  width: 92px;
  height: 92px;
  background: #e9f2f4;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  -webkit-box-shadow: 0px 5px 3px 0px rgba(214, 229, 232, 1);
  -moz-box-shadow: 0px 5px 3px 0px rgba(214, 229, 232, 1);
  box-shadow: 0px 5px 3px 0px rgba(214, 229, 232, 1);
  padding: 15px;
}

.services li .icon img {
  width: 50px;
  height: auto;
}

.services li .number {
  color: #253567;
  font-size: 26.5px;
  padding-top: 20px;
  padding-bottom: 3px;
}

.services li .title {
  color: #646e85;
  font-size: 17px;
}

.services li .date {
  color: #000;
  opacity: .6;
  font-size: 12px;
  text-align: right;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

/*coordinate-section*/
.coordinate-section {
  background: #212322;
  width: 100%;
  /*height: 100vh;*/
  padding-bottom: 100px;
}

.footprint-heading {
  text-align: center;
  padding: 10px 0;
  background: #2f3230;
  border-top: 1px solid #5e5a61;
  background: -moz-linear-gradient(left, #46262f 0%, #3b4855 49%, #323543 100%);
  background: -webkit-linear-gradient(left, #46262f 0%, #3b4855 49%, #323543 100%);
  background: linear-gradient(to left, #46262f 0%, #3b4855 49%, #323543 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#46262f', endColorstr='#323543', GradientType=0);
}

.footprint-heading h3 {
  color: #bfbdbd;
  font-size: 38px;
  letter-spacing: 2px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.89);
  display: inline-block;
  background: url(/images/footprint-icon.png) no-repeat 0 0px;
  padding-left: 65px;
  padding-top: 3px;
  background-size: contain;
}

.coordinate-tabs {
  background: #2f3230;
  position: relative;
  z-index: 9;
  border-top: 1px solid #5e5a61;
  background: #2f3230;
  border-top: 1px solid #5e5a61;
  background: -moz-linear-gradient(left, #46262f 0%, #3b4855 49%, #323543 100%);
  background: -webkit-linear-gradient(left, #46262f 0%, #3b4855 49%, #323543 100%);
  background: linear-gradient(to left, #46262f 0%, #3b4855 49%, #323543 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#46262f', endColorstr='#323543', GradientType=0);
}

.coordinate-tabs .wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.coordinate-tabs .tabs {
  text-align: center;
  height: 49px;
  white-space: nowrap;
}

.coordinate-tabs .tabs li {
  color: #a4a3a3;
  font-size: 16px;
  padding: 0 12px;
  display: inline-block;
}

.coordinate-tabs .tabs li a {
  color: #a4a3a3;
  padding: 14px 0;
  display: block;
  position: relative;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.89);
  letter-spacing: 1px;
}

.coordinate-tabs .tabs li a:hover,
.coordinate-tabs .tabs li.active a {
  color: #fff;
}

.coordinate-tabs .tabs li a:hover:after,
.coordinate-tabs .tabs li.active a:after {
  content: '';
  background: #00e160;
  height: 3px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -3px;
  border-radius: 0 0 5px 5px;
}

.world-map {
  width: 100%;
  /*height: calc(100vh - 17vh);*/
  display: none;
}

.world-map:nth-child(3) {
  display: block;
}

.world-map .wrapper {
  /*height: calc(100vh - 10vh);*/
  /*padding: 6vh 20px 15vh;*/
}

.world-map .map-inner {
  width: 1195px;
  height: 438px;
  position: relative;
  float: right;
  text-align: right;
}

.world-map .map-inner .point {
  position: relative;
}

.world-map .map-inner .point .tooltip {
  position: absolute;
  background: rgba(255, 255, 255, 0.83);
  display: flex;
  padding: 5px 8px;
  align-items: center;
  bottom: 23px;
  left: -37%;
  transform: translateX(-37%);
}

.world-map .map-inner .point .tooltip:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  border-top: 8px solid rgba(255, 255, 255, 0.83);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}

.world-map .map-inner .point .tooltip div {
  width: 19px;
}

.world-map .map-inner .point .tooltip p {
  padding: 0 10px;
  font-size: 15px;
  letter-spacing: 1px;
}

.world-map .map-inner img.map {
  height: 438px;
  width: 995px;
}

.world-map .map-slider-outer {
  float: left;
  width: 100%;
  position: absolute;
}

.world-map .map-slider .slick-list {
  width: 100% !important;
}
.world-map .map-slider .item {
  padding: 0;
}

.world-map .map-slider-outer h3 small {
  display: block;
  color: #00e461;
  font-size: 16px;
  margin-bottom: 10px;
  letter-spacing: 2px;
}

.world-map .map-slider-outer h3 {
  font-size: 44px;
  color: #fff;
  letter-spacing: 1px;
  width: 100%;
  max-width: 320px;
}

.world-map .map-slider-outer h3:after {
  font-size: 44px;
  color: #f1cf9b;
  margin-top: 20px;
  background: #d75a55;
  width: 120px;
  height: 3px;
  content: '';
  display: block;
}

.map-slider {
  margin-top: 20px;
  position: relative;
  z-index: 9;
}

.map-slider .item {
  float: left;
  margin: 0 5px;
  border-radius: 5px;
  overflow: hidden;
}

[dir='rtl'] .slick-slide {
  float: right;
}

.map-slider .thumb-img {
  width: 100%;
  position: relative;
}

.map-slider .thumb-img img {
  width: 100%;
}

.map-slider .thumb-img.mahatma-gandhi-stamps{
  background: #3c4240;
}

.map-slider .thumb-img.mahatma-gandhi-stamps img{
  width: auto;
  margin: auto;
}

.map-slider .text-wrap {
  width: 100%;
  background: #fff;
  padding: 15px 20px;
  text-align: left;
}

.map-slider .text-wrap h4 {
  color: #000;
  font-size: 3vh;
  padding-bottom: 5px;
}

.map-slider .text-wrap p {
  font-size: 2vh;
  color: #5d6072;
}

.map-slider .slick-list {
  width: calc(100% + 15px) !important;
  max-width:inherit!important;
}

.count-project {
  margin-top: 16px;
  display: inline-block;
  background: #2d3132;
  padding: 3px 15px 2px;
  border-radius: 25px;
  color: #b8b8b8;
  font-size: 16px;
}

.map-slider .slick-arrow {
  position: absolute;
  margin-top: -15px;
  border: none;
  z-index: 10;
  background: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  left: auto !important;
  margin: 0;
  bottom: -38px;
  right: 40px;
  background: #2d3132;
  text-indent: -9999px;
  top:auto;
  transform: translate(0, 0);

}
[dir='rtl'] .slick-prev{
	right:40px!important;
}
.map-slider .slick-arrow.slick-next {
  right: 0;
}

.map-slider .slick-arrow:hover {
  background: #ce4546;
}

.map-slider .slick-arrow.slick-disabled {
  background: #000;
  pointer-events: none;
}

.map-slider .slick-arrow::after {
  content: '';
  width: 15px;
  height: 15px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  position: absolute;
  left: 0;
  top: 7px;
}

.map-slider .slick-arrow.slick-next::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.map-slider.slick-slider button {
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  text-indent: -9999px;
  overflow: hidden;
}

.map-slider .slick-arrow:after {
  width: 7px;
  height: 7px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  top: 12px;
  -webkit-transform: translate(-50%) rotate(45deg);
  transform: translate(-50%) rotate(45deg);
}

.map-slider .slick-arrow.slick-prev:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  left: 13px;
}

.map-slider .slick-arrow.slick-next:after {
  left: 10px !important;
}

/*inner page*/

/*banner section*/
.banner {
  background: #152031;
}

.banner-inner {
  position: relative;
  padding: 120px 0 0;
  min-height: 370px;
  background: url(/images/banner-shape.png) no-repeat 81% top;
  background-size: 45%;
}

.banner-left {
  float: left;
}

.banner-left h3 {
  font-size: 44px;
  color: #fff;
  position: relative;
  padding-left: 15px;
}

.banner-left h3 + small {
  color: #fff;
  margin-left: 16px;
}

.banner-left h3:before {
  background: #d75a55;
  content: '';
  width: 3px;
  height: 85%;
  position: absolute;
  left: 0;
}

.breadcrumb {
  padding: 0 0 20px;
}

.breadcrumb li {
  color: #737983;
  /*opacity: .4;*/
  display: inline-block;
  padding: 0 10px 0 0;
}

.breadcrumb li:after {
  content: '';
  background: url(/images/breadcrumb-arrow.png) no-repeat left center;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-size: 100%;
}

.breadcrumb li:last-child:after {
  display: none;
}

.breadcrumb li a {
  color: #e3e4e6;
  margin-right: 8px;
}

.breadcrumb li a:hover {
  color: #fff;
  text-decoration: underline;
}

.banner-right {
  float: right;
  margin-left: auto;
}

.banner-right-data {

}

.banner-right-data li {
  float: left;
  padding: 0 20px;
  max-width: 250px;
}

.banner-right-data li strong {
  font-size: 40px;
  display: block;
  color: #e57672;
  font-weight: normal;
  padding-bottom: 3px;
}

.banner-right-data li span {
  font-size: 18px;
  display: block;
  color: #fff;
  opacity: .5;
  line-height: 25px;
  min-height: 50px;
}

.banner-right-data li span small {
  font-size: 70%;
}

.banner-right-data li strong sub {
  vertical-align: baseline;
  font-size: 20px;
}

.banner-right-data li:last-child {
  border-left: 1px solid #545a63;
  padding-right: 0;
}

/*Graph Outer*/
.graph-outer {
  margin-bottom: 30px;
}

.graph-sec {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 30px -5px 0;
}

.graph-box {
  width: calc(33.33% - 10px);
  background: #fafafa;
  border-radius: 10px;
  margin: 5px 5px;
  padding: 20px;
  min-height: 290px;
  box-shadow: 0px 0px 8px 0px rgba(95, 94, 95, 0.22);
}

.graph-box.ml15 {
  margin-left: -30px;
  box-shadow: none;
  background: transparent;
}

.graph-box .mt100 {
  margin-top: 100px;
}

.graph-box h3 {
  font-size: 25px;
  padding-bottom: 5px;
}

.graph-box h3 small {
  color: #000;
  font-size: 14px;
  display: block;
}

.graph-box p {
  font-size: 44px;
  color: #4aa50d;
}

.graph-box.pink p {
  color: #eb4b7f;
}


/*More Services*/
.more-services {
  background: #e3e0e1;
  margin-bottom: 40px;
}

.more-services-slider h3 {
  font-size: 31px;
  color: #252c3b;
  display: inline-block;
  position: relative;
  padding-left: 15px;
  margin-top: 25px;
  line-height: 1;
}

.more-services-slider h3:before {
  background: #d75a55;
  content: '';
  width: 3px;
  height: 90%;
  position: absolute;
  left: 0;
}

.more-services ul.services {
  display: block;
}

.more-services ul li {
  max-width: 238px;
  width: calc(100% - 10px);
  margin: 0 5px;
  float: left;
  height: auto;
  min-height: 285px;
  box-shadow: 0 0 7px 0px rgba(95, 94, 95, 0.22);
}

.more-services ul li .number {
  font-size: 34px;
}

.more-services ul li .icon {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-services ul li .icon >img {
  width: 50px;
  height: 50px;
}


.more-services ul li .title {
  font-size: 21px;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  max-width: 100%;
}

.more-services-slider {
  position: relative;
  min-height: 426px;
}

.more-services-slider .slick-list {
  width: calc(100% + 15px) !important;
  padding: 0 20% 0 0 !important;
}
.citizen-section .slick-arrow,
.more-services-slider .slick-arrow {
  position: absolute;
  margin-top: -15px;
  border: none;
  z-index: 10;
  background: none;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  left: auto !important;
  margin: 0;
  top: -40px;
  right: 40px;
  background: #d75a55;
}

.citizen-section .slick-arrow.slick-next,
.more-services .slick-arrow.slick-next {
  right: 0;
}

.citizen-section .slick-arrow:hover,
.more-services-slider .slick-arrow:hover {
  background: #ff0a00;
}

.citizen-section .slick-arrow.slick-disabled,
.more-services .slick-arrow.slick-disabled {
  background: rgba(215, 90, 85, 0.3);
  pointer-events: none;
}

.citizen-section .slick-arrow::after,
.more-services-slider .slick-arrow::after {
  content: '';
  width: 15px;
  height: 15px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  position: absolute;
  left: 0;
  top: 7px;
}

.citizen-section .slick-arrow.slick-next::after,
.more-services-slider .slick-arrow.slick-next::after {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.more-services-slider .slick-slider button {
  outline: none;
  cursor: pointer;
  transition: all 0.4s;
  text-indent: -9999px;
}

.citizen-section .slick-arrow:after,
.more-services .slick-arrow:after {
  width: 7px;
  height: 7px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  top: 12px;
  -webkit-transform: translate(-50%) rotate(45deg);
  transform: translate(-50%) rotate(45deg);
}

.citizen-section .slick-arrow.slick-prev:after,
.more-services .slick-arrow.slick-prev:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  left: 13px;
}

.citizen-section .slick-arrow.slick-next:after,
.more-services .slick-arrow.slick-next:after {
  left: 10px !important;
}

.citizen-section .slick-arrow {
  top: initial;
  bottom: 0;
  left: calc(50% - 20px) !important;
  transform: translateY(-50%);
}

.citizen-section .slick-arrow:before {
  content: none;
}

.citizen-section .slick-arrow.slick-next {
  left: calc(50% + 20px) !important;
}

/*India Map Outer*/
.india-map-outer {
  background: #aeddee; /* Old browsers */
  background: -moz-linear-gradient(top, #aeddee 0, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #aeddee 0, #ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #aeddee 0, #ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#aeddee', endColorstr='#ffffff', GradientType=0); /* IE6-9 */
  height: 100vh;
  /*padding: 6vh 0;*/
}

.india-map-left {
  float: left;
  width: calc(100% - 640px);
}

.india-map-left h3 {
  color: #252c3b;
  font-size: 44px;
  letter-spacing: 2px;
  display: inline-block;
  background: url(/images/map-icon.png) no-repeat 0 0px;
  padding-left: 65px;
  padding-top: 3px;
  background-size: 49px;
}


.india-map-tabs {
  text-align: center;
  padding: 7px;
  background: #fff;
  border-radius: 5px;
  margin: 20px 0 5px;
  clear: both;
  float: left;
}

.india-map-tabs li {
  color: #a4a3a3;
  font-size: 17px;
  display: inline-block;
}

.india-map-tabs li a {
  color: #252c3b;
  padding: 7px 14px;
  display: block;
  position: relative;
  letter-spacing: 1px;
  border-radius: 5px;
}

.india-map-tabs li a:hover,
.india-map-tabs li.active a {
  color: #fff;
  background: #65be83;
}

.india-map-left h4 small {
  display: block;
  color: #252c3b;
  font-size: 16px;
  margin-bottom: 3px;
  letter-spacing: 2px;
}

.state-name {
  padding: 75px 0 0;
  clear: both;
  position: relative;
  z-index: 1;
}

.state-bg {
  background: url(/images/state-bg.png) no-repeat 0 0px;
  width: 343px;
  height: 343px;
  display: block;
  position: absolute;
  z-index: -1;
  left: -80px;
  top: 0;
  transition: transform .4s ease-in-out;
  -webkit-animation: spin 30s linear infinite;
  animation: spin 30s linear infinite;
  will-change: transform;
}

.state-name h4 {
  font-size: 40px;
  color: #d9536a;
  letter-spacing: 1px;
  width: 100%;
}

.state-name h4:after {
  font-size: 44px;
  color: #f1cf9b;
  margin-top: 15px;
  background: #d75a55;
  width: 65px;
  height: 3px;
  content: '';
  display: block;
}

/*Map Sec*/
.india-map-right {
  float: right;
  width: 630px;
  position: relative;
}

.india-text {
  position: absolute;
  text-align: right;
  /*background: url(/images/india-text.png) no-repeat 0 0px;
  width: 582px;
  height: 163px;*/
  top: 0px;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1380px;
  width: 100%;
  padding: 0 20px;
}

.india-map {
  background: url(/images/india-map-bg.png) no-repeat 0 0px;
  width: 629px;
  height: 629px;
  /*border-radius: 50%;
  box-shadow: 0 20px 79px 0 rgba(127, 132, 148, 0.4);*/
  transform: scale(0.99, 0.99);
  transition: transform .4s ease-in-out;
  position: relative;
}

.india-map:before {
  content: '';
  position: absolute;
  background: url(/images/india-map-shape.png) no-repeat 0 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0;
  -webkit-animation: spin 30s linear infinite;
  animation: spin 30s linear infinite;
  will-change: transform;
}

.india-map:after {
  content: '';
  position: absolute;
  background: url(/images/india-map-shadow.png) no-repeat 0 0px;
  width: 504px;
  height: 195px;
  bottom: -90px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@-webkit-keyframes spin {
  0% {
    -moz-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
  }
}

@-o-keyframes spin {
  0% {
    -moz-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
  }
}

@-ms-keyframes spin {
  0% {
    -moz-transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
  }
  100% {
    -moz-transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
  }
}

.foot,
.foot .fp-tableCell {
  height: auto !important;
}

.footer {
  background: #1a5c9f;
  /* float: left; */
  width: 100%
}

footer > div {
  display: flex;
}

.footer p {
  color: #fff;
  letter-spacing: 2px;
  padding: 6px 0;
  line-height:  20px;
}

.footer a {
  color: #fff;
  padding: 0 8px;
}

.footer a:hover {
  text-decoration: underline;
}

/* .footer-left {
  float: left;
  width: 43%;
} */

.footer-left p:first-child {
  line-height: normal;
}

.footer-left a:first-child {
  padding-left: 0;
}

/* .footer-right {
  float: right;
  width: 37%;
  text-align: right;
} */

/* .footer-right p a {
  padding: 0;
  text-decoration: underline;
}
.footer-right p a:hover {
  color: rgba(255,255,255,0.5);
} */

#india-map-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 110px;
  /*margin-bottom: 30px;*/
}

#world-map-container {
  position: unset;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

#world-map-container .graph-box {
  margin-top: 100px;
  margin-left: 100px;
}

.destination-sec {
  background: #ddeefc;
  min-height: 250px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(95, 94, 95, 0.22);
  margin: 0
}

.destination-sec.bottom-space {
  margin-bottom: 40px;
}


.destination-sec .chart-outer {
  float: left;
  position: relative;
  width: 100%;
}

.remove-float{
  float: none!important;
}

.destination-heading {
  margin: 25px 0 0 20px;
  clear: both;
  position: relative;
  z-index: 1;
  text-align: center;
  max-width: 400px;
}

.destination-heading .inner {
  width: 440px;
}


.destination-heading .destination-heading-bg {
  background: url(/images/state-bg.png) no-repeat 0 0px;
  width: 800px;
    height: 800px;
    display: block;
    background-size: cover;
    position: absolute;
    z-index: -1;
    left: -180px;
    top: -405px;
  transition: transform .4s ease-in-out;
  -webkit-animation: spin 30s linear infinite;
  animation: spin 30s linear infinite;
  will-change: transform;
}

.destination-heading h3 {
  font-size: 44px;
  color: #253567;
  width: 100%;
}

.destination-heading h3 small {
  display: block;
  color: #252c3b;
  font-size: 23px;
  margin-bottom: 3px;
  letter-spacing: 1px;
}

.destination-heading h3:after {
  color: #f1cf9b;
  margin: 15px auto 0;
  background: #d75a55;
  width: 65px;
  height: 3px;
  content: '';
  display: block;
}

.destination-sec .inner-world-map-outer {
  width: 100%;
  padding: 15px 20px 0 20px;
}

.destination-sec .inner-world-map {
  width: 100%;
}

.destination-sec .inner-world-map .tooltip {
  position: absolute;
  background: rgba(255, 255, 255, 0.83);
  display: flex;
  padding: 5px 8px;
  align-items: center;
  bottom: 23px;
  right: 0;
}

.destination-sec .inner-world-map .tooltip p {
  padding: 0 10px;
  font-size: 15px;
  letter-spacing: 1px;
}

.select-outer {
  text-align: right;
  padding-bottom: 10px;
  position: relative;
}

.select-outer {
  text-align: right;
  padding-bottom: 10px;
  position: relative;
}

.select-outer .select-box {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}

.select-box select {
  padding: 10px 30px 10px 13px;
  color: #000;
  font-size: 16px;
  background: #fff url(/images/select-arrow.png) no-repeat right 18px;
  border-radius: 5px;
  display: inline-block;
  width: 235px;
  -webkit-appearance: none;
  box-shadow: 0px 4px 10px 0px rgba(95, 94, 95, 0.22);
  -webkit-box-shadow: 0px 4px 10px 0px rgba(95, 94, 95, 0.22);
}

/* 25 july */
.destination-sec .inner-world-map .tooltip.pink {
  background-color: #e44663;
  width: max-content;
  right: auto;
  left: calc(100% - 14px);
  border-radius: 5px 5px 5px 0;
  bottom: 21px;

}

.destination-sec .inner-world-map .tooltip.pink:after {
  content: "";
  display: block;
  position: absolute;
  height: 0;
  width: 0;

  top: 100%;
  left: 0;
  border: 8px solid #e44663;
  border-top-width: 0;
  border-right-width: 0;
  border-bottom-color: transparent;
  border-left-width: 6px

}

.destination-sec .inner-world-map .tooltip.pink p {
  padding: 0;
  color: white;
}

.chart-outer .destination-data-wrap {
  border-radius: 10px;
  background-color: white;
  padding: 10px 34px;
  float: right;
  margin: 25px -52px 0 0;
  width: 160px;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.destination-data-wrap span {
  color: rgba(0, 0, 0, 0.66);
  font-size: 14px;
  display: block;
}

.destination-data-wrap strong {
  color: #253567;
  font-size: 38px;
  display: block;
  font-weight: 400;
}

.destination-heading {
  clear: initial;
  float: left;
}

.world-map .map-inner .point .tooltip p {
  width: max-content;
}

.world-map .map-inner .point .tooltip {
  left: 50%;
  transform: translateX(-50%);
}

.graph-box .select-box select.blue {
  font-size: 19px;
  color: #388ad0;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 8px;
  width: 100%;
  border: 2px solid #a6cff2;
  border-radius: 5px;
  background: url(/images/blue-arrow.png) no-repeat 97% center/8px;
}

.graph-box .select-outer {
  padding-top: 5px;
}

/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  text-align: left;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected {
  background-color: #fff;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 16px;
  right: 10px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-color: #388ad0 transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #388ad0 transparent;
  top: 9px;
}

/*style the items (options), including the selected item:*/
.select-items div, .select-selected {
  color: #388ad0;
  padding: 5px 8px;
  font-size: 19px;
  border: 2px solid #a6cff2;
  background-color: white;
  border-radius: 5px;
  /* border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent; */
  cursor: pointer;
  user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div {
  border: none;
  border-radius: 0;
  color: #000;
}

.select-items div:hover, .same-as-selected {
  /* background-color: #a6cff2; */
  color: #388ad0;
}

.select-items {
  border-radius: 5px;
  overflow: hidden;
  background-color: transparent;
  box-shadow: 0 0 4px 0px #a6cff2;
}

/* inner events */

.banner-right-data li:first-child {
  border-left: none;
}

.events-engagement .graph-sec {
  margin: 0;
  flex-wrap: wrap
}

.events-engagement h3.sec-title {
  font-size: 44px;
  color: #253567;
  position: relative;
  padding-left: 15px;
  line-height: 1;
  margin-bottom: 22px;
}

.events-engagement h3.sec-title:before {
  background: #d75a55;
  content: '';
  width: 3px;
  height: 90%;
  position: absolute;
  left: 0;
}

.events-engagement {
  margin-top: -110px;
}

.events-engagement .coordinate-tabs {
  width: calc(100% - 370px);
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top-right-radius: 5px;
}

.clearfix:after {
  content: "";
  display: block;
  clear: both;
}

.events-engagement .select-outer {
  float: left;
  width: 370px;
  text-align: left;
  padding-bottom: 0;
  position: relative;
  margin-top: -10px;
  z-index: 1;
}

.events-engagement {
  position: relative;
  z-index: 1;
}

.events-engagement .destination-sec {
  margin-top: 0;
  padding: 39px 27px 37px;
  position: relative;

}

.events-engagement .select-selected {
  border: none;
  border-radius: 0;
  padding: 0;
  padding-left: 28px;
  font-size: 31px;
  color: #253567;
  padding-bottom: 11px;
}

.dropdown-tab-wrap .select-outer span {
  font-size: 17px;
  color: #000;
  display: block;
  padding-left: 28px;
  margin-bottom: 5px;
}

.events-engagement .destination-sec {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropdown-tab-wrap .select-outer:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: white;
  border-top-left-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.events-engagement .coordinate-tabs .tabs li {
  padding: 0 15px;
}

.events-engagement .coordinate-tabs .tabs {
  text-align: right;
}

.dropdown-tab-wrap .select-inner {
  padding-top: 16px;
}

.dropdown-tab-wrap .select-inner:after {
  content: "";
  border-bottom: 86px solid white;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  height: 0;
  width: 30px;
  position: absolute;
  top: 0;
  right: -40px;
  z-index: -1;
}

.dropdown-tab-wrap .select-inner:before {
  content: "";
  border-bottom: 86px solid #5b5e69;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  height: 0;
  width: 30px;
  position: absolute;
  top: 0;
  right: -55px;
  z-index: -1;
}

.dropdown-tab-wrap .select-selected:after {
  border: none;
  width: 32px;
  height: 10px;
  top: 0;
  background: url(/images/event-arrow.png) no-repeat center top /15px;
  transition: 0.3s;
}

.dropdown-tab-wrap .select-selected.select-arrow-active:after {
  transform: rotate(180deg);
  transition: 0.3s;
}

.events-engagement .graph-box:nth-child(3n + 1) {
  margin-left: 0;
}

.events-engagement .graph-box:nth-child(3n) {
  margin-right: 0;
}

.events-engagement .destination-sec .destination-heading-bg {
  background: url(/images/state-bg.png) no-repeat 0 0px;
  width: 343px;
  height: 343px;
  display: block;
  position: absolute;
  z-index: -1;
  right: -130px;
  top: -130px;
  transition: transform .4s ease-in-out;
  -webkit-animation: spin 30s linear infinite;
  animation: spin 30s linear infinite;
  will-change: transform;
  z-index: 0;
}

.events-engagement .select-items div {
  padding-left: 28px;
}

.events-engagement .select-items {
  background-color: white;
  padding: 10px 0;
}

.passport-report-view {
  margin-bottom: 30px;
}

.banner-overlap-contents {
  margin-top: -150px;
  margin-bottom: 40px;
  min-height: 327px;
}

.banner-overlap-contents.ent-page {
  margin-top: 35px;
}
#fullpage > div > div{
	clear:left;
}

@media only screen and (max-width: 764px){
	/*.slider img{
		object-fit:cover;
	}

	.citizen-section .svgWrapper,
	.inner-world-map-outer{
		display:none;
	}
	.citizen-right{
		width:100%;
	}
	section.citizen-section{
		padding:3vh 10px;
	}
	.header{
		left:0;
		padding:25px 15px;
		text-align:center!important;
	}
	.header a{
		width: 46%;
		margin:0 5px;
		display: inline-block;
	}
	.services{
		display:block;
	}
	.services li{
		width:47%;
		display:inline-block;
		float:left;
	}
	.word-map{
		display:none;
	}
	.world-map .map-slider-outer{
		position:static;
	}*/
	/*.wrapper{
		padding:0 25px;
	}
	.banner-overlap-contents .passport-report-view .wrapper .graph-sec .graph-box{
		width: calc(100% - 10px);
	}
	.highcharts-root,
	.highcharts-container{
		width:100%!important;
	}
	.more-services ul li{
		width:93%;
	}
	.footer-left,
	.footer-right{
		width:100%;
		text-align:center;
	}
	.more-services-slider .services{
		width: calc(100% + (100vw - 320px) / 2) !important;
	}
	.more-services-slider .slick-arrow{
		right:55px;
	}
	.more-services .slick-arrow.slick-next{
		right:10px;
	}*/
}
.banner-left{
  max-width:460px;
}
.banner-inner{
  padding-bottom:180px;
}
.banner-right-data li{
  border-left: 1px solid #545a63;
}
.india-footprint .chart-outer > h3{
  position: relative;
  z-index: 2;
}

#india-map-container .country-map-outer .highcharts-label .highcharts-text-outline {
  fill: #f8d3de;
  stroke: #f8d3de;
  stroke-width: 7px;
}

.aeroplane-container {
  background-color: transparent;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.aeroplane-container .cls-1 {
  fill: #34495e;
}

.aeroplane-container .cls-2 {
  fill: #fff;
}

.aeroplane-container .cls-3 {
  fill: #cee2f2;
}

.aeroplane-icon svg {
  width: 21px;
}


.aeroplane-1 {
  position: relative;
  width: 45px;
  height: 45px;
  margin-bottom: 20px;
  top: 250px;
  left: 150px;
}

.aeroplane-1 .aeroplane-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  transform: rotate(225deg);
  -webkit-animation: move-aeroplane-1 10s infinite;
  animation: move-aeroplane-1 10s infinite;
}

.aeroplane-1 .aeroplane-icon:after {
  position: absolute;
  right: 0;
  top: 15px;
  content: '';
  height: 1px;
  width: 0px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, rgba(0,0,0,0.09) 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  -webkit-animation: line-1 10s infinite;
  animation: line-1 10s infinite;
  animation-fill-mode: forwards;
}

@-webkit-keyframes move-aeroplane-1 {
  0% {
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transform: rotate(225deg) scale(0);
  }

  15% {
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transform: rotate(225deg) scale(0);
  }

  30% {
    bottom: 10px;
    right: 10px;
    opacity: 1;
    transform: rotate(225deg) scale(1);
  }

  70% {
    bottom: 200px;
    right: 200px;
    opacity: 1;
    transform: rotate(225deg) scale(1);
  }

  85% {
    bottom: 200px;
    right: 200px;
    opacity: 0;
    transform: rotate(225deg) scale(0);
  }

  100% {
    bottom: 200px;
    right: 200px;
    opacity: 0;
    transform: rotate(225deg) scale(0);
  }
}
@keyframes move-aeroplane-1 {
  0% {
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transform: rotate(225deg) scale(0);
  }

  15% {
    bottom: 10px;
    right: 10px;
    opacity: 0;
    transform: rotate(225deg) scale(0);
  }

  30% {
    bottom: 10px;
    right: 10px;
    opacity: 1;
    transform: rotate(225deg) scale(1);
  }

  70% {
    bottom: 200px;
    right: 200px;
    opacity: 1;
    transform: rotate(225deg) scale(1);
  }

  85% {
    bottom: 200px;
    right: 200px;
    opacity: 0;
    transform: rotate(225deg) scale(0);
  }

  100% {
    bottom: 200px;
    right: 200px;
    opacity: 0;
    transform: rotate(225deg) scale(0);
  }
}

@-webkit-keyframes line-1 {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    opacity: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
  }

  55% {
    width: 100px;
    opacity: 1;
  }

  70% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}

@keyframes line-1 {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    opacity: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
  }

  55% {
    width: 100px;
    opacity: 1;
  }

  70% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}


.aeroplane-2 {
  position: relative;
  width: 45px;
  height: 45px;
  margin-bottom: 20px;
  left: 50px;
}

.aeroplane-2 .aeroplane-icon {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: move-aeroplane-2 15s infinite;
  animation: move-aeroplane-2 15s infinite;
}

.aeroplane-2 .aeroplane-icon:after {
  position: absolute;
  right: 0;
  top: 15px;
  content: '';
  height: 1px;
  width: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, rgba(0,0,0,0.09) 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  -webkit-animation: line-1 10s infinite;
  -webkit-animation: line-2 15s infinite;
  animation: line-2 15s infinite;
  animation-fill-mode: forwards;
}

@-webkit-keyframes move-aeroplane-2 {
  0% {
    left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  15% {
    left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  30% {
    left: 10px;
    opacity: 1;
    transform: scale(1);
  }

  70% {
    left: 400px;
    opacity: 1;
    transform: scale(1);
  }

  85% {
    left: 400px;
    opacity: 0;
    transform: scale(0);
  }

  100% {
    left: 400px;
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes move-aeroplane-2 {
  0% {
    left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  15% {
    left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  30% {
    left: 10px;
    opacity: 1;
    transform: scale(1);
  }

  70% {
    left: 400px;
    opacity: 1;
    transform: scale(1);
  }

  85% {
    left: 400px;
    opacity: 0;
    transform: scale(0);
  }

  100% {
    left: 400px;
    opacity: 0;
    transform: scale(0);
  }
}

@-webkit-keyframes line-2 {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    opacity: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
  }

  55% {
    width: 100px;
    opacity: 1;
  }

  70% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}
@keyframes line-2 {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    opacity: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
  }

  55% {
    width: 100px;
    opacity: 1;
  }

  70% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}


.aeroplane-3 {
  position: relative;
  width: 45px;
  height: 45px;
  margin-bottom: 20px;
  top: 100px;
  left: 350px;
}

.aeroplane-3 .aeroplane-icon {
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(-45deg);
  -webkit-animation: move-aeroplane-3 13s infinite;
  animation: move-aeroplane-3 13s infinite;
}

.aeroplane-3 .aeroplane-icon:before {
  position: absolute;
  left: 0;
  top: 15px;
  content: '';
  height: 1px;
  width: 0px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, rgba(0,0,0,0.09) 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  -webkit-animation: line-1 10s infinite;
  -webkit-animation: line-3 13s infinite;
  animation: line-3 13s infinite;
  animation-fill-mode: forwards;
}

@-webkit-keyframes move-aeroplane-3 {
  0% {
    top: -10px;
    left: 10px;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }

  15% {
    top: -10px;
    left: 10px;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }

  30% {
    top: -10px;
    left: 10px;
    opacity: 1;
    transform: rotate(-45deg) scale(1);
  }

  70% {
    top: -180px;
    left: 200px;
    opacity: 1;
    transform: rotate(-45deg) scale(1);
  }

  85% {
    top: -180px;
    left: 200px;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }

  100% {
    top: -180px;
    left: 200px;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }
}
@keyframes move-aeroplane-3 {
  0% {
    top: -10px;
    left: 10px;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }

  15% {
    top: -10px;
    left: 10px;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }

  30% {
    top: -10px;
    left: 10px;
    opacity: 1;
    transform: rotate(-45deg) scale(1);
  }

  70% {
    top: -180px;
    left: 200px;
    opacity: 1;
    transform: rotate(-45deg) scale(1);
  }

  85% {
    top: -180px;
    left: 200px;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }

  100% {
    top: -180px;
    left: 200px;
    opacity: 0;
    transform: rotate(-45deg) scale(0);
  }
}

@-webkit-keyframes line-3 {
  0% {
    width: 0;
    opacity: 0;
    left: 0;
  }

  30% {
    width: 0;
    opacity: 0;
    left: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
    left: -100px;
  }

  55% {
    width: 100px;
    opacity: 1;
    left: -100px;
  }

  70% {
    width: 0;
    opacity: 0;
    left: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    left: 0;
  }
}
@keyframes line-3 {
  0% {
    width: 0;
    opacity: 0;
    left: 0;
  }

  30% {
    width: 0;
    opacity: 0;
    left: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
    left: -100px;
  }

  55% {
    width: 100px;
    opacity: 1;
    left: -100px;
  }

  70% {
    width: 0;
    opacity: 0;
    left: 0;
  }

  100% {
    width: 0;
    opacity: 0;
    left: 0;
  }
}


.aeroplane-4 {
  position: relative;
  width: 45px;
  height: 45px;
  left: 610px;
  top: -150px;
}

.aeroplane-4 .aeroplane-icon {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(90deg);
  -webkit-animation: move-aeroplane-4 11s infinite;
  animation: move-aeroplane-4 11s infinite;
}

.aeroplane-4 .aeroplane-icon:after {
  position: absolute;
  right: 0;
  top: 15px;
  content: '';
  height: 1px;
  width: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, rgba(0,0,0,0.09) 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  -webkit-animation: line-1 10s infinite;
  -webkit-animation: line-4 11s infinite;
  animation: line-4 11s infinite;
  animation-fill-mode: forwards;
}

@-webkit-keyframes move-aeroplane-4 {
  0% {
    top: 10px;
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }

  15% {
    top: 10px;
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }

  30% {
    top: 10px;
    opacity: 1;
    transform: rotate(90deg) scale(1);
  }

  70% {
    top: 210px;
    opacity: 1;
    transform: rotate(90deg) scale(1);
  }

  85% {
    top: 210px;
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }

  100% {
    top: 210px;
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }
}
@keyframes move-aeroplane-4 {
  0% {
    top: 10px;
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }

  15% {
    top: 10px;
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }

  30% {
    top: 10px;
    opacity: 1;
    transform: rotate(90deg) scale(1);
  }

  70% {
    top: 210px;
    opacity: 1;
    transform: rotate(90deg) scale(1);
  }

  85% {
    top: 210px;
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }

  100% {
    top: 210px;
    opacity: 0;
    transform: rotate(90deg) scale(0);
  }
}

@-webkit-keyframes line-4 {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    opacity: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
  }

  55% {
    width: 100px;
    opacity: 1;
  }

  70% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}
@keyframes line-4 {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    opacity: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
  }

  55% {
    width: 100px;
    opacity: 1;
  }

  70% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}


.aeroplane-5 {
  position: relative;
  width: 45px;
  height: 45px;
  left: 390px;
  top: 70px;
}

.aeroplane-5 .aeroplane-icon {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: move-aeroplane-5 17s infinite;
  animation: move-aeroplane-5 17s infinite;
}

.aeroplane-5 .aeroplane-icon:after {
  position: absolute;
  right: 0;
  top: 15px;
  content: '';
  height: 1px;
  width: 0;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,ffffff+100 */
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(left, rgba(0,0,0,0.09) 0%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(0,0,0,0.09) 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  -webkit-animation: line-1 10s infinite;
  -webkit-animation: line-5 17s infinite;
  animation: line-5 17s infinite;
  animation-fill-mode: forwards;
}

@-webkit-keyframes move-aeroplane-5 {
  0% {
    left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  15% {
    left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  30% {
    left: 10px;
    opacity: 1;
    transform: scale(1);
  }

  70% {
    left: 300px;
    opacity: 1;
    transform: scale(1);
  }

  85% {
    left: 300px;
    opacity: 0;
    transform: scale(0);
  }

  100% {
    left: 300px;
    opacity: 0;
    transform: scale(0);
  }
}
@keyframes move-aeroplane-5 {
  0% {
    left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  15% {
    left: 10px;
    opacity: 0;
    transform: scale(0);
  }

  30% {
    left: 10px;
    opacity: 1;
    transform: scale(1);
  }

  70% {
    left: 300px;
    opacity: 1;
    transform: scale(1);
  }

  85% {
    left: 300px;
    opacity: 0;
    transform: scale(0);
  }

  100% {
    left: 300px;
    opacity: 0;
    transform: scale(0);
  }
}

@-webkit-keyframes line-5 {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    opacity: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
  }

  55% {
    width: 100px;
    opacity: 1;
  }

  70% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}
@keyframes line-5 {
  0% {
    width: 0;
    opacity: 0;
  }

  30% {
    width: 0;
    opacity: 0;
  }

  45% {
    width: 100px;
    opacity: 1;
  }

  55% {
    width: 100px;
    opacity: 1;
  }

  70% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}

.destination-sec .inner-world-map-outer .country-map-outer .highcharts-container {
  width: 100% !important;
}

@media (max-width: 1200px) {
  .destination-sec .inner-world-map-outer .country-map-outer {
    transform: scale(0.88);
    margin-top: -100px;
    margin-left: -35px;
  }
}

@media (max-width: 1100px) {
  .citizen-section .wheel-content-container .svgWrapper {
    max-width: 325px;
  }
  .citizen-right {
    width: calc(100% - 345px);
  }

  /*.coordinate-tabs .tabs li {
    padding: 0 9px;
  }*/
  .destination-sec .inner-world-map-outer .country-map-outer {
    transform: scale(0.8);
  }
}

@media (max-width: 1024px) {
  /*.coordinate-tabs .tabs li {
    padding: 0 7px;
  }*/
  .events-engagement .graph-box:nth-child(3n + 1) {
    margin-left: 5px;
  }
  .events-engagement .graph-box:nth-child(3n) {
    margin-right: 5px;
  }
  .events-engagement .graph-box:nth-child(2n) {
    margin-right: 0;
  }
  .events-engagement .graph-box:nth-child(2n + 1) {
    margin-left: 0;
  }
  .destination-sec .inner-world-map-outer .country-map-outer {
    transform: scale(0.75);
  }
}

@media (max-width: 991px) {
  footer > div {
    flex-direction: column;
  }
  .footer-left,
  .footer-right {
    float: none;
    width: 100%;
    text-align: left;
  }
  /* .footer-right {
    margin-top: 20px;
  } */
  .banner-right-data li:first-child {
    padding-left: 0;
  }
  .services li {
    max-width: 238px;
    width: calc(100% - 10px);
  }
  .world-map .map-slider-outer {
    position: relative;
    float: none;
  }
  .coordinate-section {
    padding-bottom: 40px;
  }
  .events-engagement .coordinate-tabs {
    width: 100%;
  }
  .banner-overlap-contents.ent-page {
    margin-top: 0;
  }
  .more-services-slider .slick-slider button {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .banner-right-data li {
    padding: 0;
    border: none;
  }
  .banner-right-data li:last-child {
    border: none;
  }
  .footer-left p:first-child {
    visibility: hidden;
    margin-bottom: 20px;
  }
  .footer-left a {
    visibility: visible;
    display: block;
    padding-left: 0;
  }
  .events-engagement .graph-box {
    margin-right: 0;
    margin-left: 0;
  }
  .events-engagement .graph-box:nth-child(3n + 1) {
    margin-left: 0;
  }
  .events-engagement .graph-box:nth-child(3n) {
    margin-right: 0;
  }
  .banner-overlap-contents.ent-page .wrapper {
    padding: 0;
  }
  .dropdown-tab-wrap .select-inner:after,
  .dropdown-tab-wrap .select-inner:before {
    content: none;
  }
  .events-engagement .select-outer {
    width: 100%;
  }
  .dropdown-tab-wrap .select-outer:before {
    border-radius: 0;
  }
  .events-engagement .coordinate-tabs::after {
    content: "";
    background: linear-gradient( to left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    width: 50px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
  }
  .slider img{
    height: auto;
  }
}

@media (max-width: 480px) {
  .footprint-heading h3 {
    font-size: 27px;
    padding-left: 45px;
  }
  .citizen-right .head h3 {
    font-size: 27px;
  }
  .citizen-right .head p {
    line-height: 25px;
  }
  .dropdown-tab-wrap .select-inner:before {
    border-bottom: 75px solid #5b5e69;
  }
  .dropdown-tab-wrap .select-inner:after {
    border-bottom: 75px solid white;
  }
  .events-engagement .destination-sec {
    padding: 20px;
  }
}