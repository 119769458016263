/*footer > div {
  display: flex;
}*/

/*.footer-right {
  width: 32%;
}*/
.footer .wrapper{
  padding: 0;
  justify-content: space-between;
}

.footer .wrapper > div:first-child, 
.footer .wrapper > div:last-child{
  padding: 20px;
}

.footer-right{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 40px;
  background-color: #214d84;
}

.footer-right div:first-child{
  width: 38px;
  height: 31px;
  margin-right: 18px;
}

.footer-right div:nth-child(2) > p,
.footer-right div:nth-child(2) a
{
  padding: 0;
}

.footer-right a{
  color: #ebb228;
  border-bottom: 1px solid #ebb2286b;
}

.footer-right a:hover{
  text-decoration: none;
  color: #ffb400;
  border-bottom: 1px solid #ffb400;
}

.ReactModal__Content.footer-modal{
  position: absolute;
  overflow: hidden !important;
  border-radius: 8px !important;
  background: rgb(237, 237, 237) !important;
  outline: none;
}
.footer-desc{
  font-size: 18px;
  padding: 35px;
  height: 100%;
  overflow-y: auto;
  line-height: 1.5;
}

.footer-desc h2{
  margin-bottom: 6px;
  margin-top: 6px;
}

.footer-close{
  border: none;
  outline: none;
  margin: 10px;
  position: absolute;
  right: 0;
  background: rgb(237, 237, 237);
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 991px){
  .wrapper{
    padding: 0;
  }
  .footer-right{
    width: 100%;
  }
  .footer .wrapper > div:first-child, 
  .footer .wrapper > div:last-child{
    padding: 20px 20px 16px;
  }
  .footer-right div:first-child{
    margin-right: 16px;
  }
  .footer-right{
    justify-content: initial;
    padding: 20px;
  }
  .footer-left p{
    margin-bottom: 0;
  }
}

@media (max-width: 1024px) and (min-width: 992px){
  .footer-left{
    width: 37%;
  }
  .footer-right{
    width: 35%;
  }
}

@media (min-width: 768px){
  .ReactModal__Content.footer-modal{
    width: 62% !important;
    height: 62% !important;
  }
}