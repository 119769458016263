.infoContainer {
  position: relative;
  width: 400px;
  top: 100px;
  left: -80px; /* container is flex-end, can't change that yet */
  margin-left: 16px;
}

.infoBox {
  background: white;
  height: 140px;
  padding-left: 20px;
  border-radius: 4px;
}

.countryName {
  font-size: 28px;
  padding-top: 20px;
}

.description {
  font-size: 20px;
}

#world-map-container.inner-page-world-map {
  z-index: 2;
}

#world-map-container.inner-page-world-map .graph-box {
  margin-top: 0;
  width: 100%;
  margin-left: 0;
}
#world-map-container.inner-page-world-map .graph-box .highcharts-container {
  width: 100% !important;
  height: auto !important;
}
#world-map-container.inner-page-world-map .graph-box .highcharts-container svg {
  width: 100%;
  height: auto;
}

.left-section-table {
  width: 100%;
  margin-top: 24px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}

.left-section-table > ul {
  width: 100%;
}

.left-section-table-row {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px;
}

.left-section-table-row .left-section-table-row-radio {
  height: 14px;
  width: 14px;
  border: 2.5px solid;
  background: #fff;
  border-radius: 50%;
  margin-right: 8px;
}

.left-section-table-year {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.tableHeader {
  width: 100%;
  text-align: right;
  font-size: 14px;
  table-layout: fixed;
  padding: 2px 12px;
}

.tableHeaderCell {
  width: 40%;
}
.tableHeaderCell:first-child {
  width: 20%;
}

.left-section-table-row td {
  width: 40%;
  text-align: right;
}

.left-section-table-row td:first-child {
  width: 20%;
}

@media (max-width: 1100px) {
  #world-map-container.inner-page-world-map > div:last-child {
    transform: scale(0.9);
    margin-right: -50px;
    margin-left: -50px;
  }
}

@media (max-width: 1024px) {
  #world-map-container.inner-page-world-map > div:last-child {
    transform: scale(0.8);
    margin-right: -90px;
    margin-left: -90px;
  }
}

@media (max-width: 991px) {
  #world-map-container.inner-page-world-map > div:last-child {
    transform: none;
    margin-left: 0;
    margin-right: 0;
  }
}
