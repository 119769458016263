.solar-card{
    width:462px;
    float:left;
    width:100%;
    display:flex;
    box-shadow: 0px 16px 68px 0px rgba(0, 0, 0, 0.4);
    border-radius:5px;
    width:100%;
    overflow: hidden;
}
.solar-card .img-wrap{
    background-image: -moz-linear-gradient( 110deg, rgb(70,38,47) 0%, rgb(59,72,85) 49%, rgb(50,53,67) 100%);
    background-image: -webkit-linear-gradient( 110deg, rgb(70,38,47) 0%, rgb(59,72,85) 49%, rgb(50,53,67) 100%);
    background-image: -ms-linear-gradient( 110deg, rgb(70,38,47) 0%, rgb(59,72,85) 49%, rgb(50,53,67) 100%);
  width:98px;
  display:flex;
  align-items: center;
  padding:0 5px;
}
.solar-card .img-wrap img{
    margin-top:15px;
}
.solar-card .content-wrap{
    flex-grow: 1;
    background-color:#f4f4f4;
    flex-grow: 1;
}
.solar-card h4{
    font-size:38px;
    color:#253567;

}
.solar-card small{
    font-size:30px;
    color:#253567;
}
.solar-card .content-wrap .inner{
    padding:26px;
}

.legendsWrapper {
  display: flex;
  position: absolute;
  bottom: -70px;
  right: 20px;
}

.legend {
  display: flex;
}

.legend:first-child {
  margin-right: 16px;
}

.legendImage {
  height: 20px;
  padding-right: 6px;
}

.legendText {
  color: #ffffff;
}

@media (max-width: 480px) {
  .solar-card h4 {
    font-size: 34px;
  }
  .solar-card small {
    font-size: 26px;
  }
}
