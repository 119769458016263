.videoWrapper {
  height: 100vh;
  width: 100vw;
  background: #000000;
  position: fixed;
  z-index: 2;
  display: flex;
  align-items: center;
}

.video {
  width: 100vw;
}

.buttonWrapper {
  position: fixed;
  bottom: 4px;
  right: 24px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.loadingIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  font-size: 24px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.loadingIndicator img {
  width: 100px;
}

.loadingText {
  padding-top: 16px;
}

.button {
  cursor: pointer;
  text-align: center;
  padding: 8px;
  font-size: 20px;
  color: #ffffff;
}

.skipIntro {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border: 1px solid #ffffff;
  background: #000000;
}

.skipIntro > img {
  transform: scale(0.5);
}

.muteToggle {
  width: 78px;
  height: 81px;
  transform: scale(0.5);
  background-repeat: no-repeat;
}

.muteIcon {
  background-image: url(/images/mute.png);
}

.unmuteIcon {
  background-image: url(/images/sound.png);
}
